import { TaskInterface } from 'store/reducers/adminTasks/types';
import { IsActiveInterface } from 'store/reducers/visualisations/types';
import { EntityType, LoadingInterface } from 'types/store';
import { FontsProjectSettingsInterface } from 'types/types';

export interface RLSProjectSettingsInterface extends IsActiveInterface {
  type: EntityType | 'none';
  entityId: string | null;
}

export type ProjectSettingsInterface = {
  isViewMode: boolean;
  realData: {
    isForce: boolean;
    isShow: boolean;
  };
  gridSpacing: number;
  isShowWorkSpace: boolean;
  // fontSettings: FontFamilySettingsInterfaceInterface;
  scaleOfPage: IsActiveInterface & {
    width: number;
  };
  font: {
    isShow: boolean;
    name: string;
  };
  /*TODO: Comment on RLS and wait for the server */
  // rls: RLSProjectSettingsInterface;
};

export interface ProjectTaskInterface extends LoadingInterface {
  task: TaskInterface | null;
}

export interface ProjectSettingsStateInterface {
  projectSettings: ProjectSettingsInterface;
  serverStateOfProjectSettings: ProjectSettingsInterface | null;
  projectSettingsLoading: boolean;
  themeSettingsLoading: boolean;
  dashboardComponentsRendering: boolean;
  dashboardComponentsLoading: boolean;
  dashboardComponentsLoaded: boolean;
  dashboardElementsLoading: boolean;
  defaultModelId: string | null;
  lastSettingTimestamp: Date | null;
  serverStateOfDefaultModelId: string | null;
  defaultModelLoading: boolean;
  saveInProgress: boolean;
  loadInProgress: boolean;
  projectTask: ProjectTaskInterface;
  fontsProjectSettings: FontsProjectSettingsInterface;
}

/* Action Types */

export enum ProjectSettingsActionsTypes {
  UPDATE_PROJECT_SETTINGS = 'UPDATE_PROJECT_SETTINGS',
  UPDATE_ACTIVE_PROJECT_TASK = 'UPDATE_ACTIVE_PROJECT_TASK',
  UPDATE_DASHBOARD_COMPONENT_RENDERING = 'UPDATE_DASHBOARD_COMPONENT_RENDERING',
  UPLOAD_DASHBOARD_SETTINGS = 'UPLOAD_DASHBOARD_SETTINGS',
  LOAD_FONTS_SETTINGS = 'LOAD_FONTS_SETTINGS',
  LOAD_PROJECT_SETTINGS = 'LOAD_PROJECT_SETTINGS',
  LOAD_PROJECT_SETTINGS_FROM_SNAPSHOT = 'LOAD_PROJECT_SETTINGS_FROM_SNAPSHOT',
  LOAD_FULL_PROJECT_SETTINGS = 'LOAD_FULL_PROJECT_SETTINGS',
  LOAD_THEME_SETTINGS = 'LOAD_THEME_SETTINGS',
  LOAD_DASHBOARD_COMPONENTS_SETTINGS = 'LOAD_DASHBOARD_COMPONENTS_SETTINGS',
  UPDATE_DEFAULT_MODEL_ID = 'UPDATE_DEFAULT_MODEL_ID',
  LOAD_DEFAULT_MODEL_ID = 'LOAD_DEFAULT_MODEL_ID',
  LOAD_DEFAULT_MODEL_ID_FROM_SNAPSHOT = 'LOAD_DEFAULT_MODEL_ID_FROM_SNAPSHOT',
  CLEAR_PROJECT_SETTINGS_STORE = 'CLEAR_PROJECT_SETTINGS_STORE',
  LOAD_WORKSPACE_DATA = 'LOAD_WORKSPACE_DATA',
  LOAD_WORKSPACE_DATA_FROM_SNAPSHOT = 'LOAD_WORKSPACE_DATA_FROM_SNAPSHOT',
  LOAD_PAGE_DATA = 'LOAD_PAGE_DATA',
  LOAD_PROJECT_FILTERS = 'LOAD_PROJECT_FILTERS',
  UPLOAD_WORKSPACE_DATA = 'UPLOAD_WORKSPACE_DATA',
  CLEAR_WORKSPACE_DATA = 'CLEAR_WORKSPACE_DATA',
  SAVE_SETTINGS_SNAPSHOT = 'SAVE_SETTINGS_SNAPSHOT',
  DELETE_SETTINGS_SNAPSHOT = 'DELETE_SETTINGS_SNAPSHOT',
  LOAD_LAST_SETTINGS_TIMESTAMP = 'LOAD_LAST_SETTINGS_TIMESTAMP',
  UPLOAD_PROJECT_SCREEN = 'UPLOAD_PROJECT_SCREEN',
  LOAD_PROJECT_TASK = 'LOAD_PROJECT_TASK',
  CREATE_PROJECT_TASK = 'CREATE_PROJECT_TASK',
  UPDATE_PROJECT_TASK = 'UPDATE_PROJECT_TASK',
  SET_PROJECT_TASK = 'SET_PROJECT_TASK',
}

/* Payloads */

export type UpdateProjectSettingsPayload = Partial<ProjectSettingsInterface>;

export type UploadProjectScreenPayload = {
  projectId: string;
  screen: FormData;
};
