import styled from 'styled-components';
import { defaultScrollbarMixin } from 'constants/styles';
import { CursorType } from 'types/styles';

export interface FlexContainerProps {
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | '';
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  textAlign?: 'center' | 'start' | 'end';
  alignContent?: 'center' | 'flex-start' | 'flex-end';
  flexWrap?: 'wrap' | 'nowrap';
  flexShrink?: string;
  width?: string;
  height?: string;
  minHeight?: string;
  minWidth?: string;
  maxHeight?: string;
  maxWidth?: string;
  padding?: string;
  margin?: string;
  flexBasis?: string;
  backgroundColor?: string;
  background?: string;
  textColor?: string;
  position?: 'relative' | 'absolute' | 'fixed' | 'sticky' | 'static';
  top?: string;
  bottom?: string;
  right?: string | number;
  left?: string | number;
  zIndex?: string;
  overflow?: 'hidden' | 'visible' | 'auto';
  borderRadius?: string | number;
  border?: string;
  borderRight?: string;
  borderLeft?: string;
  borderBottom?: string;
  boxShadow?: string;
  marginRight?: string;
  marginLeft?: string;
  marginTop?: string;
  marginBottom?: string;
  gap?: string;
  hasError?: boolean;
  flex?: string;
  visibilityProp?: 'hidden' | 'visible';
  display?: 'flex' | 'none' | 'block' | 'inline-flex';
  cursor?: CursorType;
  transition?: string;
  transform?: string;
  opacity?: number;
  scale?: number;
  transformOrigin?: string;
  overflowX?: string;
  overflowY?: string;
  fontFamily?: string;
  flexGrow?: number;
  order?: number;
  overflowHover?: boolean;
  hoverBackgroundColor?: string | null;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  flex: ${({ flex }) => flex};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}, sans-serif` : undefined)};
  flex-grow: ${({ flex }) => flex};
  position: ${({ position }) => position};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  order: ${({ order }) => order};
  align-content: ${({ alignContent }) => alignContent};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transition: ${({ transition }) => transition};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: ${({ padding }) => padding};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin: ${({ margin }) => margin};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  overflow: ${({ overflow, overflowHover }) => (overflowHover ? 'hidden' : overflow)};
  flex-basis: ${({ flexBasis }) => flexBasis};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ border }) => border};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  border-bottom: ${({ borderBottom }) => borderBottom};
  box-shadow: ${({ boxShadow }) => boxShadow};
  visibility: ${({ visibilityProp }) => visibilityProp};
  display: ${({ display }) => display || 'flex'};
  cursor: ${({ cursor }) => cursor};
  transform: ${({ transform }) => transform};
  opacity: ${({ opacity }) => opacity};
  scale: ${({ scale }) => scale};
  transform-origin: ${({ transformOrigin }) => transformOrigin};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};

  :hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor && hoverBackgroundColor};
  }

  &:hover {
    overflow: ${({ overflowHover }) => overflowHover && 'auto'};
  }

  ${defaultScrollbarMixin};
`;
