import { IdInterface } from 'types/store';

export interface ColorsInterface extends IdInterface {
  color: string;
}

export type ShapeType = 'square' | 'circle';

export enum ShapeEnum {
  SQUARE = 'square',
  CIRCLE = 'circle',
}
