import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import {
  initialFilterStoreState,
  initialFnByFilterType,
  removeFilterByIdFunc,
  updateFilterSettingsFunc,
} from 'store/reducers/filters/constants';
import {
  AddNewFilterPayload,
  EnabledFilterDataType,
  FilterDataType,
  FilterType,
  FilterTypeInterface,
  UpdateEnabledFilterPayload,
} from 'store/reducers/filters/types';
import {
  loadEnabledFiltersByPageIdAction,
  loadEnabledFiltersFromSnapshotAction,
  loadFieldsAction,
  loadFiltersByPageIdAction,
  loadFiltersByProjectIdAction,
  loadFiltersFromSnapshotAction,
  loadGlobalEnabledFiltersAction,
} from 'store/reducers/filters/actions';
import { setSliceFn } from 'constants/store';
import { mergeRightMapOfSetWithLeft } from 'utils/utils';
import { uploadDashboardSettingsAction } from 'store/reducers/projectSettings/actions';
import { NameInterface } from 'types/store';

export const filterSlice = createSlice({
  name: 'filters',
  initialState: initialFilterStoreState,
  reducers: {
    addNewFilter: (
      state,
      {
        payload: { type, id, pageId, name, ...createFilterParams },
      }: PayloadAction<AddNewFilterPayload & FilterTypeInterface<FilterType> & NameInterface>,
    ) => {
      state.filters = {
        ...state.filters,
        [id]: initialFnByFilterType[type]({ id, pageId, name, ...createFilterParams }),
      };

      state.filtersByProject = {
        ...state.filters,
        [id]: initialFnByFilterType[type]({ id, pageId, name, ...createFilterParams }),
      };

      const setOfIds = state.filtersByPages[pageId] || new Set<string>();
      setOfIds.add(id);

      state.filtersByPages = { ...state.filtersByPages, [pageId]: setOfIds };
    },

    addNewFilterByData: (state, { payload: data }: PayloadAction<FilterDataType>) => {
      const { id, pageId } = data;

      state.filters = {
        ...state.filters,
        [id]: data,
      };

      const setOfIds = state.filtersByPages[pageId] || new Set<string>();
      setOfIds.add(id);

      state.filtersByPages = { ...state.filtersByPages, [pageId]: setOfIds };
    },

    removeFilterById: removeFilterByIdFunc,

    updateFilter: updateFilterSettingsFunc,

    updateFilterForGroup: updateFilterSettingsFunc,

    enableFilter: (state, { payload: enabledFilterData }: PayloadAction<EnabledFilterDataType>) => {
      const { id, pageId } = enabledFilterData;

      state.enabledFilters = {
        ...state.enabledFilters,
        [id]: enabledFilterData,
      };

      const setOfIds = state.enabledFiltersByPages[pageId] || new Set<string>();
      setOfIds.add(id);

      state.enabledFiltersByPages = { ...state.enabledFiltersByPages, [pageId]: setOfIds };
    },

    updateEnabledFilter: (state, { payload: { id, data } }: PayloadAction<UpdateEnabledFilterPayload>) => {
      const enabledFilter = state.enabledFilters[id];

      if (enabledFilter) {
        state.enabledFilters[id] = { ...enabledFilter, ...data } as EnabledFilterDataType;
      }
    },

    disableFilterById: (state, { payload: id }: PayloadAction<string>) => {
      const pageId = state.enabledFilters[id]?.pageId;

      if (pageId) {
        delete state.enabledFilters[id];

        const setOfIds = state.enabledFiltersByPages[pageId] || new Set<string>();
        setOfIds.delete(id);

        state.enabledFiltersByPages = { ...state.enabledFiltersByPages, [pageId]: setOfIds };
      }
    },

    removeFilterValuesById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.filtersData.filtersValues[id]) {
        delete state.filtersData.filtersValues[id];
      }
    },

    removeFromFilterLoadingListById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.filtersValuesLoadingList.hasOwnProperty(id)) {
        delete state.filtersValuesLoadingList[id];
      }
    },

    addToAlreadyLoadedByPageId: (state, { payload: pageId }: PayloadAction<string>) => {
      const currentFiltersState = state.alreadyLoadedContent.filters || new Set<string>();
      const currentEnabledFiltersState = state.alreadyLoadedContent.enabledFilters || new Set<string>();
      currentFiltersState.add(pageId);
      currentEnabledFiltersState.add(pageId);

      state.alreadyLoadedContent.filters = currentFiltersState;
      state.alreadyLoadedContent.enabledFilters = currentEnabledFiltersState;
    },

    //----------------------RETURN FOR HISTORY----------------------

    returnFilterById: removeFilterByIdFunc,

    returnFilterSettings: updateFilterSettingsFunc,

    setSlice: setSliceFn,
  },

  extraReducers: (builder) => {
    builder.addCase(loadFiltersByProjectIdAction.fulfilled, (state, { payload: filters }) => {
      const setOfFilters = filters.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: FilterDataType });

      state.filtersByProject = setOfFilters;
    });
    builder.addCase(
      loadFieldsAction.pending,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        },
      ) => {
        state.filtersValuesLoadingList = { ...state.filtersValuesLoadingList, [id]: true };
      },
    );
    builder.addCase(
      loadFieldsAction.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
          payload,
        },
      ) => {
        state.filtersErrorsList = { ...state.filtersErrorsList, [id]: null };
        state.filtersData.filtersValues = { ...state.filtersData.filtersValues, [id]: payload.data };
        state.filtersValuesLoadingList = { ...state.filtersValuesLoadingList, [id]: false };
      },
    );
    builder.addCase(
      loadFieldsAction.rejected,
      (
        state,
        {
          meta: {
            arg: { id },
          },
          payload,
        },
      ) => {
        state.filtersErrorsList = { ...state.filtersErrorsList, [id]: payload };
        state.filtersData.filtersValues = { ...state.filtersData.filtersValues, [id]: [] };
        state.filtersValuesLoadingList = { ...state.filtersValuesLoadingList, [id]: false };
      },
    );

    builder.addCase(
      loadFiltersByPageIdAction.fulfilled,
      (
        state,
        {
          payload: { filters, filtersByPages },
          meta: {
            arg: { pageId },
          },
        },
      ) => {
        state.filters = { ...state.filters, ...filters };
        state.serverStateOfFilters = { ...state.filters, ...filters };

        const mergedFilters = mergeRightMapOfSetWithLeft(state.filtersByPages, filtersByPages);

        state.filtersByPages = { ...mergedFilters };

        const newAlreadyLoadedContent = state.alreadyLoadedContent.filters;
        newAlreadyLoadedContent.add(pageId);
        state.alreadyLoadedContent.filters = newAlreadyLoadedContent;
      },
    );

    builder.addCase(
      loadFiltersFromSnapshotAction.fulfilled,
      (state, { payload: { filters, filtersByPages, filtersByProject } }) => {
        state.filters = filters;
        state.filtersByPages = filtersByPages;

        if (filtersByProject) {
          state.filtersByProject = filtersByProject;
        }
      },
    );

    builder.addCase(
      loadEnabledFiltersByPageIdAction.fulfilled,
      (
        state,
        {
          payload: { enabledFilters, enabledFiltersByPages },
          meta: {
            arg: { pageId },
          },
        },
      ) => {
        state.enabledFilters = { ...state.enabledFilters, ...enabledFilters };
        state.serverStateOfEnabledFilters = { ...state.serverStateOfEnabledFilters, ...enabledFilters };

        const mergedEnabledFilters = mergeRightMapOfSetWithLeft(state.enabledFiltersByPages, enabledFiltersByPages);

        state.enabledFiltersByPages = { ...mergedEnabledFilters };

        const newAlreadyLoadedContent = state.alreadyLoadedContent.enabledFilters;
        newAlreadyLoadedContent.add(pageId);
        state.alreadyLoadedContent.enabledFilters = newAlreadyLoadedContent;
      },
    );
    builder.addCase(
      loadEnabledFiltersFromSnapshotAction.fulfilled,
      (state, { payload: { enabledFilters, enabledFiltersByPages } }) => {
        state.enabledFilters = enabledFilters;
        state.enabledFiltersByPages = enabledFiltersByPages;
      },
    );
    builder.addCase(loadGlobalEnabledFiltersAction.fulfilled, (state, { payload: { enabledFilters, enabledFiltersByPages } }) => {
      state.enabledFilters = { ...state.enabledFilters, ...enabledFilters };
      state.serverStateOfEnabledFilters = { ...state.serverStateOfEnabledFilters, ...enabledFilters };

      const mergedEnabledFilters = mergeRightMapOfSetWithLeft(state.enabledFiltersByPages, enabledFiltersByPages);

      state.enabledFiltersByPages = { ...mergedEnabledFilters };
    });
    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfFilters) {
        state.serverStateOfFilters = state.filters;
      }
      if (state.serverStateOfEnabledFilters) {
        state.serverStateOfEnabledFilters = state.enabledFilters;
      }
    });
  },
});

export const {
  addNewFilter,
  addNewFilterByData,
  updateFilter,
  removeFilterById,
  disableFilterById,
  enableFilter,
  updateEnabledFilter,
  removeFromFilterLoadingListById,
  removeFilterValuesById,
  addToAlreadyLoadedByPageId,
  setSlice,
  returnFilterById,
  returnFilterSettings,
  updateFilterForGroup,
} = filterSlice.actions;

export default filterSlice.reducer;
