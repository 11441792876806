import React, { useCallback } from 'react';
import { NoopValueType } from 'types/global';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { ViewFontSettingsInterface } from 'types/store';
import { Select } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { getFontsProjectSettings } from 'store/reducers/projectSettings/getters';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { Combobox } from 'modules/ui/Combobox';

interface BorderSettingsProps {
  onChange: NoopValueType<ViewFontSettingsInterface>;
  value: ViewFontSettingsInterface;
  disabledSwitcher?: boolean;
  disabledVariants?: boolean;
  isMainContainer?: boolean;
}

export const FontSettings = ({
  value,
  onChange,
  disabledVariants = false,
  disabledSwitcher = false,
  isMainContainer = false,
}: BorderSettingsProps) => {
  const { fontFamily, variants } = value;

  const { fontsProjectSettingsList } = useSelector(getFontsProjectSettings);

  const fontsFamilyItems = fontsProjectSettingsList?.map(({ fontsFamily }) => ({ name: fontsFamily, value: fontsFamily }));
  const fontSelectFamilyItems = fontsProjectSettingsList?.find(({ fontsFamily }) => fontsFamily === fontFamily);

  const fontFamilyItems = fontSelectFamilyItems?.variants.map(({ fontFamily }) => ({ name: fontFamily, value: fontFamily }));

  const onChangeSelectVariants = useCallback(
    (selectFontsVariant: string) => {
      fontSelectFamilyItems?.variants?.map(({ fontFamily, style, urlTTF, urlWOFF, weight }) => {
        if (fontFamily === selectFontsVariant) {
          onChange({ ...value, variants: { fontFamily, style, urlWOFF, urlTTF, weight } });
        }
      });
    },
    [fontSelectFamilyItems?.variants, onChange, value],
  );

  const onChangeFont = useCallback(
    (selectFonts: string) => {
      fontsProjectSettingsList?.map(({ fontsFamily }) => {
        if (fontsFamily === selectFonts) {
          onChange({ ...value, fontFamily: selectFonts, variants: null });
        }
      });
    },
    [fontsProjectSettingsList, onChange, value],
  );

  const handleChange = () => onChange({ ...value, isActive: !value.isActive });

  const Container = useCallback(
    () => (
      <FlexContainer flexDirection="row" justifyContent="flex-start" width="100%" gap="8px">
        <ElementContainerSettings>
          <Select
            width="150px"
            needBackground={false}
            name="fontFamily"
            value={fontFamily}
            onChange={onChangeFont}
            options={fontsFamilyItems}
          />
        </ElementContainerSettings>

        {!disabledVariants && (
          <ElementContainerSettings>
            {/*<Select*/}
            {/*  needBackground={false}*/}
            {/*  width="150px"*/}
            {/*  name="variantsFontFamily"*/}
            {/*  value={variants?.fontFamily}*/}
            {/*  onChange={onChangeSelectVariants}*/}
            {/*  options={fontFamilyItems}*/}
            {/*/>*/}

            <Combobox
              disableClearable
              name="flows"
              options={fontFamilyItems || []}
              tooltipDisableHoverListener
              width="100%"
              value={
                variants && {
                  name: variants.fontFamily,
                  value: variants.fontFamily,
                }
              }
              onChange={({ name }) => onChangeSelectVariants(name)}
            />
          </ElementContainerSettings>
        )}
      </FlexContainer>
    ),
    [disabledVariants, fontFamily, fontFamilyItems, fontsFamilyItems, onChangeFont, onChangeSelectVariants, variants],
  );

  return (
    <>
      {!isMainContainer && (
        <GroupContainerSettings
          titleText="Локальный шрифт"
          switcherState={value.isActive}
          switcherChange={!disabledSwitcher ? handleChange : undefined}
          showBottomLine
        >
          {Container()}
        </GroupContainerSettings>
      )}
      {isMainContainer && (
        <MainContainerSettings
          titleText="Локальный шрифт"
          switcherState={value.isActive}
          switcherChange={!disabledSwitcher ? handleChange : undefined}
        >
          {Container()}
        </MainContainerSettings>
      )}
    </>
  );
};
