import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import {
  defaultProjectSettings,
  initialProjectSettingsState,
  updateProjectSettingsFunc,
} from 'store/reducers/projectSettings/constants';
import {
  loadDashboardComponentsAction,
  loadDefaultModelIdAction,
  loadDefaultModelIdFromSnapshotAction,
  loadFontsProjectSettingsAction,
  loadFullProjectSettingsAction,
  loadLastSettingsTimestampAction,
  loadPageDataAction,
  loadProjectSettingsAction,
  loadProjectSettingsFromSnapshotAction,
  loadProjectTaskAction,
  loadThemeSettingsAction,
  loadWorkspaceDataAction,
  uploadDashboardSettingsAction,
  uploadWorkspaceDataAction,
} from 'store/reducers/projectSettings/actions';
import { parseISO } from 'utils/dates';

export const projectSettingsSlice = createSlice({
  name: 'projectSettings',
  initialState: initialProjectSettingsState,
  reducers: {
    updateDashboardComponentRendering: (state, { payload }: PayloadAction<boolean>) => {
      state.dashboardComponentsRendering = payload;
    },

    updateProjectSettings: updateProjectSettingsFunc,

    updateDefaultModelId: (state, { payload: defaultModelId }: PayloadAction<string | null>) => {
      state.defaultModelId = defaultModelId;
    },

    //----------------------RETURN FOR HISTORY----------------------

    returnProjectSettings: updateProjectSettingsFunc,

    setSlice: setSliceFn,
    setProjectTask: (state, { payload }: PayloadAction<TaskInterface>) => {
      state.projectTask.task = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProjectSettingsAction.pending, (state) => {
      state.projectSettings = defaultProjectSettings;
    });
    builder.addCase(loadProjectSettingsAction.fulfilled, (state, { payload: projectSettings }) => {
      state.projectSettings = projectSettings;
      state.serverStateOfProjectSettings = projectSettings;
    });
    builder.addCase(loadProjectSettingsAction.rejected, (state) => {
      state.projectSettings = defaultProjectSettings;
    });
    builder.addCase(loadProjectSettingsFromSnapshotAction.fulfilled, (state, { payload: projectSettings }) => {
      state.projectSettings = projectSettings;
    });

    builder.addCase(loadFullProjectSettingsAction.pending, (state) => {
      state.projectSettingsLoading = true;
    });
    builder.addCase(loadFullProjectSettingsAction.fulfilled, (state) => {
      state.projectSettingsLoading = false;
    });
    builder.addCase(loadFullProjectSettingsAction.rejected, (state) => {
      state.projectSettingsLoading = false;
    });

    builder.addCase(loadThemeSettingsAction.pending, (state) => {
      state.themeSettingsLoading = true;
    });
    builder.addCase(loadThemeSettingsAction.fulfilled, (state) => {
      state.themeSettingsLoading = false;
    });
    builder.addCase(loadThemeSettingsAction.rejected, (state) => {
      state.themeSettingsLoading = false;
    });

    builder.addCase(loadDashboardComponentsAction.pending, (state) => {
      state.dashboardComponentsLoading = true;
    });
    builder.addCase(loadDashboardComponentsAction.fulfilled, (state) => {
      state.dashboardComponentsLoading = false;
    });
    builder.addCase(loadDashboardComponentsAction.rejected, (state) => {
      state.dashboardComponentsLoading = false;
    });
    builder.addCase(loadPageDataAction.pending, (state) => {
      state.dashboardElementsLoading = true;
      state.loadInProgress = true;
    });
    builder.addCase(loadPageDataAction.fulfilled, (state) => {
      state.dashboardElementsLoading = false;
      state.loadInProgress = false;
    });
    builder.addCase(loadPageDataAction.rejected, (state) => {
      state.dashboardElementsLoading = false;
      state.loadInProgress = false;
    });
    builder.addCase(loadWorkspaceDataAction.pending, (state) => {
      state.loadInProgress = true;
      state.dashboardComponentsLoaded = false;
    });
    builder.addCase(loadWorkspaceDataAction.fulfilled, (state) => {
      state.loadInProgress = false;
      state.dashboardComponentsLoaded = true;
    });
    builder.addCase(loadWorkspaceDataAction.rejected, (state) => {
      state.loadInProgress = false;
      state.dashboardComponentsLoaded = false;
    });
    builder.addCase(uploadWorkspaceDataAction.pending, (state) => {
      state.saveInProgress = true;
    });
    builder.addCase(uploadWorkspaceDataAction.fulfilled, (state) => {
      state.saveInProgress = false;
    });
    builder.addCase(uploadWorkspaceDataAction.rejected, (state) => {
      state.saveInProgress = false;
    });

    builder.addCase(loadProjectTaskAction.pending, (state) => {
      state.projectTask.task = null;
      state.projectTask.loading = true;
    });
    builder.addCase(loadProjectTaskAction.fulfilled, (state, { payload: task }) => {
      state.projectTask.task = task;
      state.projectTask.loading = false;
    });
    builder.addCase(loadProjectTaskAction.rejected, (state) => {
      state.projectTask.task = null;
      state.projectTask.loading = false;
    });

    builder.addCase(loadFontsProjectSettingsAction.pending, (state) => {
      state.fontsProjectSettings.fontsProjectSettingsList = [];
      state.fontsProjectSettings.currentFont = '';
      state.fontsProjectSettings.loading = true;
    });
    builder.addCase(loadFontsProjectSettingsAction.fulfilled, (state, { payload: { allFonts, currentFont } }) => {
      state.fontsProjectSettings.fontsProjectSettingsList = allFonts;
      state.fontsProjectSettings.currentFont = currentFont;
      state.fontsProjectSettings.loading = false;
    });
    builder.addCase(loadFontsProjectSettingsAction.rejected, (state) => {
      state.fontsProjectSettings.fontsProjectSettingsList = [];
      state.fontsProjectSettings.currentFont = '';
      state.fontsProjectSettings.loading = false;
    });

    builder.addCase(loadDefaultModelIdAction.pending, (state) => {
      state.defaultModelId = null;
      state.defaultModelLoading = true;
    });
    builder.addCase(loadDefaultModelIdAction.fulfilled, (state, { payload: defaultModelId }) => {
      state.defaultModelId = defaultModelId;
      state.serverStateOfDefaultModelId = defaultModelId;
      state.defaultModelLoading = false;
    });
    builder.addCase(loadDefaultModelIdAction.rejected, (state) => {
      state.defaultModelId = null;
      state.defaultModelLoading = false;
    });
    builder.addCase(loadDefaultModelIdFromSnapshotAction.fulfilled, (state, { payload: defaultModelId }) => {
      state.defaultModelId = defaultModelId;
      state.serverStateOfDefaultModelId = defaultModelId;
    });
    builder.addCase(loadLastSettingsTimestampAction.fulfilled, (state, { payload: lastSettingTimestamp }) => {
      state.lastSettingTimestamp = parseISO(lastSettingTimestamp);
    });
    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfProjectSettings) {
        state.serverStateOfProjectSettings = state.projectSettings;
      }
      state.serverStateOfDefaultModelId = state.defaultModelId;
    });
  },
});

export const {
  updateProjectSettings,
  updateDefaultModelId,
  setSlice,
  updateDashboardComponentRendering,
  returnProjectSettings,
  setProjectTask,
} = projectSettingsSlice.actions;

export default projectSettingsSlice.reducer;
