import ColorTheme from 'constants/ColorTheme';
import { defaultScrollbarMixin } from 'constants/styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { cssVar, rgba } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IProps {
  type?: 'text' | 'password' | 'number';
  name: string;
  placeholder?: string;
  width?: string;
  disabled?: boolean;
  value?: string | number;
  needBackground?: boolean;
  needBorderBottom?: boolean;
  rows?: number;
  maxRows?: number;
  onChange?: (e: any) => void;
}

const TextArea: FC<IProps> = ({
  type = 'text',
  name,
  placeholder = '',
  width = '212px',
  disabled = false,
  value,
  needBackground = true,
  needBorderBottom = true,
  rows = 10,
  maxRows,
  ...props
}) => {
  return (
    <CustomTextArea
      type={type}
      name={name}
      placeholder={placeholder}
      width={width}
      disabled={disabled}
      value={value}
      needBackground={needBackground}
      needBorderBottom={needBorderBottom}
      rows={rows}
      maxRows={maxRows}
      {...props}
    />
  );
};

export default TextArea;

const CustomTextArea = styled.textarea<IProps>`
  width: ${(props) => props.width};
  background: ${(props) =>
    props.needBackground ? `var(${ColorVarsEnum.Level_5_application})` : `var(${ColorVarsEnum.Level_3_menu})`};
  min-height: 30px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: inherit;
  display: flex;
  align-items: flex-end;
  color: ${`var(${ColorVarsEnum.Level_1})`};
  border: unset;
  border-bottom: ${(props) => (props.needBorderBottom ? `1px solid var(${ColorVarsEnum.Level_4})` : 'unset')};
  transition: all 200ms ease, height 0ms;
  padding: 8px !important;
  max-height: ${(props) => (props.maxRows ? `${props.maxRows * 24}px` : 'unset')};

  :hover {
    box-shadow: ${(props) => (!props.disabled ? `inset 0 0 0 1px var(${ColorVarsEnum.Level_3})` : 'unset')};
  }

  :focus {
    outline: none;
    box-shadow: unset;
    background: ${`var(${ColorVarsEnum.Level_5_application})`};
    border-bottom: 1px solid ${`var(${ColorVarsEnum.Level_4})`};
  }

  ::placeholder {
    color: ${`var(${ColorVarsEnum.Level_2})`};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border-bottom: ${(props) => (props.needBorderBottom ? `1px solid var(${ColorVarsEnum.Level_4})` : 'unset')};
    -webkit-text-fill-color: ${`var(${ColorVarsEnum.Level_1})`};
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => (props.needBackground ? `var(${ColorVarsEnum.Level_5_application})` : `var(${ColorVarsEnum.Level_3_menu})`)}
      inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ${defaultScrollbarMixin};
`;
