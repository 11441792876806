import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import {
  deleteFonts,
  loadAdminFontsProjectSettings,
  loadVersionStrategy,
  updateAdminFontsProjectSettings,
  updateVersionStrategy,
  uploadFontsFiles,
} from 'store/reducers/adminSettings/api';
import { AdminSettingsActionsTypes } from 'store/reducers/adminSettings/constants';
import { setVersionStrategy, updateCurrentFontSettings, updateFontSettings } from 'store/reducers/adminSettings/index';
import { DeleteFontsInterface, UpdateCurrentFontPayload, VersionStrategy } from 'store/reducers/adminSettings/types';
import Snackbar from 'services/Snackbar';
import { FontFamilyDataSettingsInterface } from 'types/store';
import { FontsProjectSettingsAndSelectFontListInterface } from 'types/types';
import env from 'config/settings';
import { SourcesActionsTypes } from 'store/reducers/sources/types';
import { transformFileListToFormData } from 'utils/utils';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadVersionStrategyAction = createAsyncThunk<VersionStrategy, void, { rejectValue: null }>(
  AdminSettingsActionsTypes.LOAD_VERSION_STRATEGY,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadVersionStrategy();
      const strategy = response.data.settingDeleteOldIncoming;

      return {
        type: strategy.type,
        ...(strategy.type === 'time' && { ageVersion: strategy.ageVersion }),
        ...(strategy.type === 'items' && { count: strategy.count }),
      };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {} as VersionStrategy;
    }
  },
);

export const updateVersionStrategyAction = createAsyncThunk<VersionStrategy, VersionStrategy>(
  AdminSettingsActionsTypes.UPDATE_VERSION_STRATEGY,
  async (props, { rejectWithValue }) => {
    try {
      const updateParams = {
        type: props.type,
        ageVersion: props.ageVersion || 0,
        count: props.count || 0,
      };

      const response = await updateVersionStrategy(updateParams);
      const strategy = response.data.settingDeleteOldIncoming;
      Snackbar.show('Изменение успешно сохранены', 'success');

      return {
        type: strategy.type,
        ...(strategy.type === 'time' && { ageVersion: strategy.ageVersion }),
        ...(strategy.type === 'items' && { count: strategy.count }),
      };
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadAdminFontsProjectSettingsAction = createAsyncThunk<
  FontsProjectSettingsAndSelectFontListInterface,
  void,
  { rejectValue: null }
>(AdminSettingsActionsTypes.LOAD_ADMIN_FONTS_SETTINGS, async (_, { rejectWithValue }) => {
  try {
    const response = await loadAdminFontsProjectSettings();
    return {
      allFonts: response.data.apiAdminFonts.map(({ fontsFamily, variants }) => ({
        fontsFamily,
        variants: variants.map((variant) => ({
          ...variant,
          urlTTF: `${env.API}/${variant?.urlTTF?.url}`,
          urlWOFF: `${env.API}/${variant?.urlWOFF?.url}`,
        })),
      })),
      currentFont: response.data.apiAdminCurrentFont,
    };
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return {} as FontsProjectSettingsAndSelectFontListInterface;
  }
});

export const updateAdminFontsProjectSettingsAction = createAsyncThunk<string, string>(
  AdminSettingsActionsTypes.UPDATE_PROJECT_FONTS,
  async (font, { rejectWithValue }) => {
    try {
      const response = await updateAdminFontsProjectSettings(font);
      Snackbar.show('Фонт изменено', 'success');

      return response.data.apiAdminCurrentFont;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteFontsAction = createAsyncThunk<
  FontsProjectSettingsAndSelectFontListInterface,
  DeleteFontsInterface[],
  { rejectValue: null }
>(AdminSettingsActionsTypes.DELETE_FONTS, async (fonts, { rejectWithValue }) => {
  try {
    const response = await deleteFonts({ fonts });
    Snackbar.show('Удалено', 'success');

    return {
      allFonts: response.data.apiAdminFonts.map(({ fontsFamily, variants }) => ({
        fontsFamily,
        variants: variants.map((variant) => ({
          ...variant,
          urlTTF: `${env.API}/${variant?.urlTTF?.url}`,
          urlWOFF: `${env.API}/${variant?.urlWOFF?.url}`,
        })),
      })),
      currentFont: response.data.apiAdminCurrentFont,
    };
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const uploadSourceFileTypeAction = createAsyncThunk<string, FileList, { rejectValue: null }>(
  SourcesActionsTypes.UPLOAD_FILE,
  async (files, { rejectWithValue }) => {
    try {
      const uploadFiles = transformFileListToFormData('files[]', files);

      const response = await uploadFontsFiles(uploadFiles);

      Snackbar.show('Файл успешно загружен.', 'success');

      return response.data.kind;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const setVersionStrategyAction = createAsyncThunk<void, { strategy: VersionStrategy }>(
  AdminSettingsActionsTypes.SET_VERSION_STRATEGY,
  ({ strategy }, { dispatch }) => {
    dispatch(setVersionStrategy(strategy));
  },
);

export const updateFontSettingsAction = createAsyncThunk<void, { fontData: FontFamilyDataSettingsInterface }>(
  AdminSettingsActionsTypes.UPDATE_FONTS_SETTINGS,
  ({ fontData }, { dispatch }) => {
    dispatch(updateFontSettings(fontData));
  },
);

export const updateCurrentFontSettingsAction = createAsyncThunk<void, UpdateCurrentFontPayload>(
  AdminSettingsActionsTypes.UPDATE_CURRENT_FONT,
  ({ currentFont }, { dispatch }) => {
    dispatch(updateCurrentFontSettings({ currentFont }));
  },
);
