import React from 'react';
import { getProjectIdUrl, RoutesAdminURL, RoutesURL } from './constants';
import { PAGES, RouteConfig } from './types';

export const routesConfig: Record<PAGES, RouteConfig> = {
  [PAGES.LOGIN]: {
    path: '/login',
    access: 'all',
    title: 'Страница входа',
    component: React.lazy(() => import('components/loginPage')),
  },
  [PAGES.MODELS]: {
    path: getProjectIdUrl(RoutesURL.model),
    access: 'private',
    title: 'Модели',
    component: React.lazy(() => import('pages/Models')),
    isProjectRoute: true,
  },
  [PAGES.CONSOLE]: {
    path: getProjectIdUrl(RoutesURL.console),
    access: 'private',
    title: 'Консоль',
    component: React.lazy(() => import('components/console')),
    isProjectRoute: true,
  },
  [PAGES.WORKSPACE]: {
    path: `${getProjectIdUrl(RoutesURL.board)}/:pageId`,
    access: 'private',
    title: 'Рабочее пространство',
    component: React.lazy(() => import('modules/workspace')),
    isProjectRoute: true,
  },
  [PAGES.ACTIVATION_KEY]: {
    path: RoutesAdminURL.activationKey,
    access: 'admin',
    title: 'Активация ключа',
    component: React.lazy(() => import('pages/ActivationKeyPage')),
  },
  [PAGES.ADMIN_LICENSES]: {
    path: RoutesAdminURL.adminLicenses,
    access: 'admin',
    title: 'Лицензии админа',
    component: React.lazy(() => import('components/admin/licenses')),
  },
  [PAGES.ADMIN_GROUPS]: {
    path: RoutesAdminURL.adminGroups,
    access: 'admin',
    title: 'Группы админа',
    component: React.lazy(() => import('components/admin/groups')),
  },
  [PAGES.ADMIN_FLOWS]: {
    path: RoutesAdminURL.adminFlows,
    access: 'admin',
    title: 'Потоки админа',
    component: React.lazy(() => import('components/admin/flows')),
  },
  [PAGES.ADMIN_TASKS]: {
    path: RoutesAdminURL.adminTasks,
    access: 'admin',
    title: 'Задачи админа',
    component: React.lazy(() => import('components/admin/tasks')),
  },
  [PAGES.ADMIN_ACTIVE_DIRECTORIES]: {
    path: RoutesAdminURL.adminActiveDirectories,
    access: 'admin',
    title: 'Активные директории админа',
    component: React.lazy(() => import('components/admin/activeDirectories')),
  },
  [PAGES.ADMIN_SOURCES]: {
    path: RoutesAdminURL.adminSources,
    access: 'admin',
    title: 'Источники админа',
    component: React.lazy(() => import('components/admin/sources')),
  },
  [PAGES.ADMIN_SETTINGS]: {
    path: RoutesAdminURL.adminSettings,
    access: 'admin',
    title: 'Настройки админа',
    component: React.lazy(() => import('components/admin/settings')),
  },
  [PAGES.ADMIN_USERS]: {
    path: RoutesAdminURL.adminUser,
    access: 'admin',
    title: 'Пользователи админа',
    component: React.lazy(() => import('components/admin/usersPage')),
  },
  [PAGES.PROJECTS_FLOWS]: {
    path: `${RoutesURL.projectsFlowsId}${RoutesURL.list}`,
    access: 'private',
    title: 'Управление проектами',
    component: React.lazy(() => import('components/projectsManager')),
  },
  [PAGES.PROJECTS_ACCESS]: {
    path: `${RoutesURL.projectsFlowsId}${RoutesURL.access}`,
    access: 'private',
    title: 'Доступ к проектам',
    component: React.lazy(() => import('components/projectsManager')),
  },
  [PAGES.PROJECTS_DRAFTS]: {
    path: RoutesURL.projectsFlowsDrafts,
    access: 'private',
    title: 'Черновики проектов',
    component: React.lazy(() => import('components/projectsManager')),
  },
};
