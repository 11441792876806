import { Color } from 'modules/settingsContainer/ColorPicker/styles';
import { ColorGrid, ColorGridProps } from './styles';
import { isValidHex } from 'utils/formatting';
import { v4 } from 'uuid';
import { ColorsInterface } from './types';
import { NoopType } from 'types/global';
import { PickerWrapper } from 'components/shared/PickerWrapper';
import { StyledTooltip } from 'modules/ui/StyledTooltip';

export interface ColorTransparentProps extends ColorGridProps {
  onClick?: NoopType;
  disabledDeleteIcon?: boolean;
  onDelete?: NoopType;
  name?: string;
}

export const ColorTransparent = ({
  color,
  onClick,
  shape,
  name,
  colorSize,
  active,
  onDelete,
  disabledDeleteIcon = false,
}: ColorTransparentProps) => {
  const transparentColor = isValidHex(color) ? color.slice(0, 7) : color;
  const colors: ColorsInterface[] = [
    { color, id: v4() },
    { color: transparentColor, id: v4() },
    { color: transparentColor, id: v4() },
    { color, id: v4() },
  ];

  const Container = () => (
    <StyledTooltip placement="top" title={name || ''}>
      <ColorGrid shape={shape} colorSize={colorSize} color={color} active={active} onClick={onClick}>
        {colors.map(({ color, id }) => (
          <Color key={id} color={color} colorSizePercent="100%" active={false} />
        ))}
      </ColorGrid>
    </StyledTooltip>
  );

  return (
    <>
      {!disabledDeleteIcon && onDelete && (
        <PickerWrapper onDelete={onDelete}>
          <Container />
        </PickerWrapper>
      )}

      {disabledDeleteIcon && <Container />}
    </>
  );
};
