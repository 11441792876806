import { ColorOperatorEnum, Currency, VisualisationOperationTypesType } from 'store/reducers/visualisations/types';
import { SelectItemInterface } from 'modules/ui/Select';
import { JoinTypeType, OperatorRelationType } from 'store/reducers/models/types';

export const MOCK_GRAPHIC_OPERATION_TYPE_EXTENDED: Array<{ name: string; value: VisualisationOperationTypesType }> = [
  { name: 'Сумма', value: 'sum' },
  { name: 'Количество', value: 'count' },
  { name: 'Кол-во уникальных', value: 'countUnique' },
  { name: 'Среднее', value: 'avg' },
  { name: 'Минимальное', value: 'min' },
  { name: 'Максимальное', value: 'max' },
  { name: 'По выражению', value: 'other' },
];

export const MOCK_JOIN_TYPE: Array<SelectItemInterface<JoinTypeType>> = [
  { name: 'INNER', value: 'INNER' },
  { name: 'FULL', value: 'FULL' },
  { name: 'LEFT', value: 'LEFT' },
  { name: 'RIGHT', value: 'RIGHT' },
];

export const MOCK_OPERATION_TYPE: Array<SelectItemInterface<OperatorRelationType>> = [
  { name: '=', value: '=' },
  { name: '>', value: '>' },
  { name: '≥', value: '≥' },
  { name: '<', value: '<' },
  { name: '≤', value: '≤' },
  { name: '≠', value: '≠' },
];

export const MOCK_MONTH_NAME = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export interface IDictionary {
  [key: number]: string;
}

export const DICTIONARY_OF_WEEK_LONG: IDictionary = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресение',
};

export const DICTIONARY_OF_WEEK_SHORT: IDictionary = {
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
  7: 'Вс',
};

export const DICTIONARY_OF_MONTH_LONG: IDictionary = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь',
};

export const DICTIONARY_OF_MONTH_SHORT: IDictionary = {
  1: 'Янв',
  2: 'Фев',
  3: 'Март',
  4: 'Апр',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Авг',
  9: 'Сен',
  10: 'Окт',
  11: 'Ноя',
  12: 'Дек',
};

export const DICTIONARY_OF_QUARTER_LONG: IDictionary = {
  1: '1 квартал',
  2: '2 квартал',
  3: '3 квартал',
  4: '4 квартал',
};

export const DICTIONARY_OF_QUARTER_SHORT: IDictionary = {
  1: '1 кв',
  2: '2 кв',
  3: '3 кв',
  4: '4 кв',
};

export const CURRENCY: Array<{ name: string; value: Currency }> = [
  { name: '₽', value: 'RUB' },
  { name: '$', value: 'USD' },
  { name: '€', value: 'EUR' },
];

export const STRING_OPERATION_TYPES: Array<{
  name: string;
  value: string;
  type: ColorOperatorEnum;
}> = [
  { name: 'Текст содержит', value: 'textContains', type: ColorOperatorEnum.SINGLE },
  { name: 'Текст не содержит', value: 'textNotContains', type: ColorOperatorEnum.SINGLE },
  { name: 'Текст начинается с', value: 'textStartsWith', type: ColorOperatorEnum.SINGLE },
  { name: 'Текст заканчивается на', value: 'textEndsWith', type: ColorOperatorEnum.SINGLE },
  { name: 'Текст в точности', value: 'textExact', type: ColorOperatorEnum.SINGLE },
];

export const NUMBER_OPERATION_TYPES: Array<{
  name: string;
  value: string;
  type: ColorOperatorEnum;
}> = [
  { name: 'Больше', value: 'greaterThan', type: ColorOperatorEnum.SINGLE },
  { name: 'Меньше', value: 'lessThan', type: ColorOperatorEnum.SINGLE },
  { name: 'Больше или равно', value: 'greaterOrEqual', type: ColorOperatorEnum.SINGLE },
  { name: 'Меньше или равно', value: 'lessOrEqual', type: ColorOperatorEnum.SINGLE },
  { name: 'Равно', value: 'equal', type: ColorOperatorEnum.SINGLE },
  { name: 'Не равно', value: 'notEqual', type: ColorOperatorEnum.SINGLE },
  { name: 'Между', value: 'between', type: ColorOperatorEnum.DEFAULT },
  { name: 'Не между', value: 'notBetween', type: ColorOperatorEnum.DEFAULT },
];

export const DATE_OPERATION_TYPES: Array<{
  name: string;
  value: string;
  type: ColorOperatorEnum;
}> = [
  { name: 'Дата', value: 'dateExact', type: ColorOperatorEnum.SINGLE },
  { name: 'Дата до', value: 'dateBefore', type: ColorOperatorEnum.SINGLE },
  { name: 'Дата после', value: 'dateAfter', type: ColorOperatorEnum.SINGLE },
  { name: 'Дата между', value: 'dateBetween', type: ColorOperatorEnum.DEFAULT },
  { name: 'Дата не между', value: 'dateNotBetween', type: ColorOperatorEnum.DEFAULT },
];

export const OPERATION_NAME_BY_VALUE: Record<string, string> = {
  textContains: 'Текст содержит',
  textNotContains: 'Текст не содержит',
  textStartsWith: 'Текст начинается с',
  textEndsWith: 'Текст заканчивается на',
  textExact: 'Текст в точности',
  greaterThan: 'Больше',
  lessThan: 'Меньше',
  greaterOrEqual: 'Больше или равно',
  lessOrEqual: 'Меньше или равно',
  equal: 'Равно',
  notEqual: 'Не равно',
  between: 'Между',
  notBetween: 'Не между',
  dateExact: 'Дата',
  dateBefore: 'Дата до',
  dateAfter: 'Дата после',
  dateBetween: 'Дата между',
  dateNotBetween: 'Дата не между',
};

export const MOCK_GRAPHIC_OPERATION_TYPE_RULES: Array<{
  name: string;
  value: VisualisationOperationTypesType;
  type: Array<'number' | 'string' | 'date'>;
}> = [
  { name: 'Сумма', value: 'sum', type: ['number'] },
  { name: 'Количество', value: 'count', type: ['number', 'string', 'date'] },
  { name: 'Кол-во уникальных', value: 'countUnique', type: ['number', 'string', 'date'] },
  { name: 'Среднее', value: 'avg', type: ['number'] },
  { name: 'Минимальное', value: 'min', type: ['number'] },
  { name: 'Максимальное', value: 'max', type: ['number'] },
  { name: 'Текст', value: 'text', type: ['string', 'date'] },
  { name: 'По выражению', value: 'other', type: ['number', 'string', 'date'] },
];
