import { hsl, interpolateHsl, interpolateRgbBasis, scaleSequential } from 'd3';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isEqual from 'lodash/isEqual';
import { ColorSlider, ColorSliderProps } from 'modules/ui/colors/ColorSlider';
import { getHls } from 'utils/utils';
import { DisableHlsInterface } from 'modules/ui/colors/HlsColorEditor/types';
import React, { useEffect, useMemo, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { OnValueChange } from 'types/global';
import { ColorEditType, HexAndHLSColorInterface, HlsColorInterface } from 'types/store';
import { useSelector } from 'react-redux';
import { getActiveThemeColors } from 'store/reducers/themes/getters';

interface ValueContainerProps
  extends OnValueChange<number>,
    Pick<ColorSliderProps, 'min' | 'max' | 'step' | 'colorFn' | 'disabled'> {
  title: string;
}

const ValueContainer = ({ value, onChange, title, ...sliderProps }: ValueContainerProps) => {
  return (
    <FlexContainer width="100%" gap="5px" alignItems="center">
      <FlexContainer width="55px">
        <PrimaryTextSpan whiteSpace="nowrap" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
          {title}: {Math.floor(value)}
        </PrimaryTextSpan>
      </FlexContainer>
      <ColorSlider width={257} value={value} onChange={onChange} {...sliderProps} />
    </FlexContainer>
  );
};

export interface HlsColorEditorProps extends DisableHlsInterface {
  value: HexAndHLSColorInterface;
  onChange: (color: HexAndHLSColorInterface, type: ColorEditType) => void;
}

export const HlsColorEditor = ({ value, onChange, disableHls }: HlsColorEditorProps) => {
  const hlsValue = useMemo(() => ({ h: value.h, s: value.s, l: value.l, opacity: value.opacity || 100 }), [value]);

  const [localHlsValues, setLocalHlsValues] = useState<HlsColorInterface>(hlsValue);

  const { h, s, l, opacity = 1 } = localHlsValues;

  const themeColors = useSelector(getActiveThemeColors);

  const hColorFn = scaleSequential((h) => hsl(h * 360, s / 100, l / 100, opacity).toString());
  const sColorFn = interpolateHsl(hsl(0, 0, l / 100, opacity).hex(), hsl(h, 1, l / 100, opacity / 100).hex());
  const lColorFn = interpolateRgbBasis(['black', hsl(h, s / 100, l / 100, opacity / 100).formatHex(), 'white']);
  const opacityFn = interpolateRgbBasis([
    themeColors['--color-l_3_menu'],
    hsl(h, s / 100, l / 100, opacity).formatHex(),
    hsl(h, s / 100, l / 100).formatHex(),
  ]);

  const isChangedLocalHls = !isEqual(hlsValue, localHlsValues);

  useEffect(() => {
    setLocalHlsValues(hlsValue);
  }, [hlsValue]);

  useEffect(() => {
    if (isChangedLocalHls) {
      onChange({ ...value, h, hex: getHls({ ...hlsValue, h }) }, 'h');
    }
    /* TODO: check this no deps */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [h]);

  useEffect(() => {
    if (isChangedLocalHls) {
      onChange({ ...value, s, hex: getHls({ ...hlsValue, s }) }, 's');
    }
    /* TODO: check this no deps */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s]);

  useEffect(() => {
    if (isChangedLocalHls) {
      onChange({ ...value, l, hex: getHls({ ...hlsValue, l }) }, 'l');
    }
    /* TODO: check this no deps */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [l]);

  useEffect(() => {
    if (isChangedLocalHls) {
      const newHex =
        hsl(h, s / 100, l / 100).formatHex() +
        Math.round(opacity * 2.55)
          .toString(16)
          .padStart(2, '0');
      onChange({ ...value, opacity: opacity, hex: newHex }, 'opacity');
    }
    /* TODO: check this no deps */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opacity]);

  return (
    <>
      <FlexContainer flexDirection="column" width="100%">
        <ValueContainer
          value={h}
          title="H"
          onChange={(h) => setLocalHlsValues((currentValue) => ({ ...currentValue, h }))}
          max={360}
          step={0.0001}
          colorFn={hColorFn}
          disabled={disableHls?.h}
        />
        <ValueContainer
          value={s}
          step={0.0001}
          title="S"
          onChange={(s) => setLocalHlsValues((currentValue) => ({ ...currentValue, s }))}
          colorFn={sColorFn}
          disabled={disableHls?.s}
        />
        <ValueContainer
          value={l}
          step={0.0001}
          title="L"
          onChange={(l) => setLocalHlsValues((currentValue) => ({ ...currentValue, l }))}
          colorFn={lColorFn}
          disabled={disableHls?.l}
        />
        <ValueContainer
          value={opacity}
          step={0.0001}
          title="A"
          onChange={(opacity) => setLocalHlsValues((currentValue) => ({ ...currentValue, opacity }))}
          colorFn={opacityFn}
          disabled={disableHls?.opacity}
        />
      </FlexContainer>
    </>
  );
};
