import { createSelector } from 'reselect';
import { getState } from 'store/utils';
import isEqual from 'lodash/isEqual';
import { sortByYCoordinateFn } from 'utils/utils';
import { getPageIds } from '../projectPages/getters';
import { GroupWidgetSettingsInterface } from './types';

export const getGroupsState = createSelector(getState, (state) => state.groupsVisualisations);

export const getWidgetGroups = createSelector(getGroupsState, (state) => state.widgetGroups);

export const getWidgetGroupById = (id: string) => createSelector(getWidgetGroups, (widgetGroups) => widgetGroups[id]);

export const getGroupVisualisationIdsByPage = createSelector(getGroupsState, (state) => state.groupsByPages);

export const getGroupVisualisationIdsByPageId = (pageId: string) =>
  createSelector(getGroupVisualisationIdsByPage, (pages) => pages[pageId]);

export const getGroupVisualisationIdsByPageAsArray = (pageId?: string) =>
  createSelector(getState, (state) => Array.from(getGroupVisualisationIdsByPageId(pageId || '')(state) || []));

export const getWidgetsGroupsByPageId = (pageId?: string) =>
  createSelector([getState, getGroupVisualisationIdsByPageAsArray(pageId)], (state, groupVisualisationsIds) =>
    groupVisualisationsIds
      .reduce<GroupWidgetSettingsInterface[]>((result, id) => {
        const group = getWidgetGroupById(id)(state);

        if (group) {
          return [...result, group];
        }

        return result;
      }, [])
      .sort(sortByYCoordinateFn),
  );

export const getServerStateOfGroupsVisualisations = createSelector(
  getGroupsState,
  (state) => state.serverStateOfGroupsVisualisations,
);

export const getHasChangesOfGroups = createSelector(
  [getServerStateOfGroupsVisualisations, getWidgetGroups],
  (serverState, currentState) => (serverState === null ? false : !isEqual(serverState, currentState)),
);

export const getGroupAlreadyLoadedContent = createSelector(getGroupsState, (state) => state.alreadyLoadedContent);

export const getGroupsAlreadyLoaded = (pageId: string) =>
  createSelector(getGroupAlreadyLoadedContent, (alreadyLoadedContent) => alreadyLoadedContent.groups.has(pageId));

export const getGroupVisualisations = createSelector([getState, getPageIds], (state, pageIds) =>
  pageIds.reduce<{ pageId: string; groupVisualisations: FastBoard.API.GroupVisualisationsV3[] }[]>((results, pageId) => {
    if (getGroupsAlreadyLoaded(pageId)(state)) {
      return [
        ...results,
        {
          pageId,
          groupVisualisations: getWidgetsGroupsByPageId(pageId)(state) as FastBoard.API.GroupVisualisationsV3[],
        },
      ];
    }

    return results;
  }, []),
);
