import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  initialModelsStoreState,
  onModelRuleFulfilled,
  onModelRuleRejected,
  onPendingModelRule,
} from 'store/reducers/models/constants';
import {
  ActiveModelItemAliasInterface,
  AddNewTabPayload,
  AllModelRulesListInterface,
  AllVariableValuesInterface,
  ModelRuleDataInterface,
  ModelRuleDataUsersAndGroupsInterface,
  ModelRuleUserAndGroupInterface,
  UpdateTabByIdPayload,
} from 'store/reducers/models/types';
import {
  importingUsersAndGroupsRuleAction,
  loadAllModelRulesAction,
  loadGroupAction,
  loadModelRuleAction,
  loadModelsFromDataAction,
  loadTablePreviewAction,
  loadTables,
  loadTabsAction,
  loadUserModelAction,
} from 'store/reducers/models/actions';
import { getMapObject } from 'utils/utils';
import { setSliceFn } from 'constants/store';
import { IdInterface } from 'types/store';

export const modelsSlice = createSlice({
  name: 'models',
  initialState: initialModelsStoreState,
  reducers: {
    addNewTab: (state, { payload: tab }: PayloadAction<AddNewTabPayload>) => {
      state.tabs = {
        ...state.tabs,
        [tab.id]: tab,
      };
    },

    removeTabById: (state, { payload: id }: PayloadAction<string>) => {
      if (state.tabs[id]) {
        delete state.tabs[id];
      }
    },

    updateTabById: (state, { payload: { id, data } }: PayloadAction<UpdateTabByIdPayload>) => {
      const tab = state.tabs[id];

      if (tab) {
        state.tabs[id] = { ...tab, ...data };
      }
    },

    setActiveTab: (state, { payload: id }: PayloadAction<string | null>) => {
      state.activeTabId = id;
    },

    setActiveModelItemAlias: (state, { payload: alias }: PayloadAction<ActiveModelItemAliasInterface | null>) => {
      state.activeModelItemAlias = alias;
    },

    deleteByIdModelRule: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.allModelRules.allModelRulesList = state.allModelRules.allModelRulesList.filter((rule) => rule.id !== id);
    },
    addModelRule: (state, { payload: data }: PayloadAction<AllModelRulesListInterface>) => {
      state.allModelRules.allModelRulesList = [data, ...state.allModelRules.allModelRulesList];
    },
    updateModelRule: (state, { payload: rulesData }: PayloadAction<ModelRuleDataInterface | null>) => {
      state.modelRuleDataUsersAndGroups.modelRule.modelRulesData = rulesData;
    },
    updateAllVariables: (state, { payload: data }: PayloadAction<AllVariableValuesInterface[]>) => {
      state.modelRuleDataUsersAndGroups.allVariables = data;
    },
    updateModelRuleById: (state, { payload: modelRules }: PayloadAction<AllModelRulesListInterface[]>) => {
      state.allModelRules.allModelRulesList = modelRules;
    },
    createRuleUsers: (state, { payload: data }: PayloadAction<ModelRuleUserAndGroupInterface[]>) => {
      state.modelRuleDataUsersAndGroups.modelRuleUsers = data;
    },
    createRuleGroups: (state, { payload: data }: PayloadAction<ModelRuleUserAndGroupInterface[]>) => {
      state.modelRuleDataUsersAndGroups.modelRuleGroups = data;
    },
    updateRuleMoveToGroups: (state, { payload: data }: PayloadAction<ModelRuleUserAndGroupInterface[]>) => {
      state.modelRuleDataUsersAndGroups.modelRuleGroups = data;
    },
    deleteByIdRuleUser: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.modelRuleDataUsersAndGroups.modelRuleUsers = state.modelRuleDataUsersAndGroups.modelRuleUsers.filter(
        (user) => user.id !== id,
      );
    },
    deleteByIdRuleGroup: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.modelRuleDataUsersAndGroups.modelRuleGroups = state.modelRuleDataUsersAndGroups.modelRuleGroups.filter(
        (group) => group.id !== id,
      );
    },
    setActiveCellName: (state, { payload: name }: PayloadAction<string>) => {
      state.activeCellName = name;
    },
    changeActiveRuleUserId: (state, { payload }: PayloadAction<ModelRuleUserAndGroupInterface | null>) => {
      state.modelRuleDataUsersAndGroups.activeModelRuleUser = payload;
    },
    changeActiveRuleGroupId: (state, { payload }: PayloadAction<ModelRuleUserAndGroupInterface | null>) => {
      state.modelRuleDataUsersAndGroups.activeModelRuleGroup = payload;
    },
    updateRuleUsers: (state, { payload: data }: PayloadAction<ModelRuleUserAndGroupInterface[]>) => {
      state.modelRuleDataUsersAndGroups.modelRuleUsers = data;
    },
    updateRuleGroups: (state, { payload: data }: PayloadAction<ModelRuleUserAndGroupInterface[]>) => {
      state.modelRuleDataUsersAndGroups.modelRuleGroups = data;
    },
    clearRuleModel: (state, { payload: data }: PayloadAction<ModelRuleDataUsersAndGroupsInterface>) => {
      state.modelRuleDataUsersAndGroups = data;
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadTables.pending, (state) => {
      state.tables = {};
      state.tablesLoading = true;
    });

    builder.addCase(loadTables.fulfilled, (state, { payload: tables }) => {
      state.tables = tables;
      state.tablesLoading = false;
    });

    builder.addCase(loadTables.rejected, (state) => {
      state.tables = {};
      state.tablesLoading = false;
    });

    builder.addCase(loadTabsAction.pending, (state) => {
      state.tabs = {};
      state.tabsLoading = true;
    });

    builder.addCase(loadTabsAction.fulfilled, (state, { payload: tabs }) => {
      state.tabs = tabs;
      state.tabsLoading = false;
    });

    builder.addCase(loadTabsAction.rejected, (state) => {
      state.tabs = {};
      state.tabsLoading = false;
    });

    builder.addCase(loadModelsFromDataAction.pending, (state) => {
      state.modelsFrom = {};
      state.modelsFromLoading = true;
    });

    builder.addCase(loadModelsFromDataAction.fulfilled, (state, { payload: modelsFrom }) => {
      state.modelsFrom = getMapObject(modelsFrom, 'id');
      state.modelsFromLoading = false;
    });

    builder.addCase(loadModelsFromDataAction.rejected, (state) => {
      state.modelsFrom = {};
      state.modelsFromLoading = false;
    });

    builder.addCase(
      loadTablePreviewAction.pending,
      (
        state,
        {
          meta: {
            arg: { table },
          },
        },
      ) => {
        state.tablePreviewLoading = state.tablePreviewLoading.add(table);
      },
    );

    builder.addCase(
      loadTablePreviewAction.fulfilled,
      (
        state,
        {
          payload: tablePreview,
          meta: {
            arg: { table },
          },
        },
      ) => {
        const tablePreviewLoading = state.tablePreviewLoading;
        tablePreviewLoading.delete(table);

        state.tablePreviewLoading = tablePreviewLoading;
        state.tablePreviews = { ...state.tablePreviews, [table]: tablePreview };
      },
    );

    builder.addCase(
      loadTablePreviewAction.rejected,
      (
        state,
        {
          meta: {
            arg: { table },
          },
        },
      ) => {
        const tablePreviewLoading = state.tablePreviewLoading;
        tablePreviewLoading.delete(table);

        state.tablePreviewLoading = tablePreviewLoading;
      },
    );

    builder.addCase(loadAllModelRulesAction.pending, (state) => {
      state.allModelRules = { ...state.allModelRules, loading: true };
      state.allModelRules.allModelRulesList = [];
    });
    builder.addCase(loadAllModelRulesAction.fulfilled, (state, { payload }) => {
      state.allModelRules = { ...state.allModelRules, loading: false };
      state.allModelRules.allModelRulesList = payload;
    });
    builder.addCase(loadAllModelRulesAction.rejected, (state) => {
      state.allModelRules = { ...state.allModelRules, loading: false };
      state.allModelRules.allModelRulesList = [];
    });

    builder.addCase(loadGroupAction.pending, (state) => {
      state.modelRuleDataUsersAndGroups.groups = [];
    });
    builder.addCase(loadGroupAction.fulfilled, (state, { payload }) => {
      state.modelRuleDataUsersAndGroups.groups = payload;
    });
    builder.addCase(loadGroupAction.rejected, (state) => {
      state.modelRuleDataUsersAndGroups.groups = [];
    });

    builder.addCase(loadUserModelAction.pending, (state) => {
      state.modelRuleDataUsersAndGroups.users = [];
    });
    builder.addCase(loadUserModelAction.fulfilled, (state, { payload }) => {
      state.modelRuleDataUsersAndGroups.users = payload;
    });
    builder.addCase(loadUserModelAction.rejected, (state) => {
      state.modelRuleDataUsersAndGroups.users = [];
    });

    builder.addCase(loadModelRuleAction.pending, onPendingModelRule);
    builder.addCase(loadModelRuleAction.fulfilled, onModelRuleFulfilled);
    builder.addCase(loadModelRuleAction.rejected, onModelRuleRejected);

    builder.addCase(importingUsersAndGroupsRuleAction.pending, onPendingModelRule);
    builder.addCase(importingUsersAndGroupsRuleAction.fulfilled, onModelRuleFulfilled);
    builder.addCase(importingUsersAndGroupsRuleAction.rejected, onModelRuleRejected);
  },
});

export const {
  addNewTab,
  updateTabById,
  removeTabById,
  setActiveTab,
  addModelRule,
  updateModelRule,
  setActiveModelItemAlias,
  deleteByIdModelRule,
  createRuleUsers,
  updateRuleUsers,
  deleteByIdRuleGroup,
  createRuleGroups,
  updateRuleMoveToGroups,
  setActiveCellName,
  deleteByIdRuleUser,
  updateRuleGroups,
  changeActiveRuleUserId,
  updateAllVariables,
  changeActiveRuleGroupId,
  updateModelRuleById,
  clearRuleModel,

  setSlice,
} = modelsSlice.actions;

export default modelsSlice.reducer;
