import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialHintState } from './constants';

export const hintSlice = createSlice({
  name: 'hint',
  initialState: initialHintState,
  reducers: {
    setHint: (state, { payload }: PayloadAction<{ isShow: boolean; text: string }>) => {
      state.isShow = payload.isShow;
      state.text = payload.text;
    },
  },
});

export const { setHint } = hintSlice.actions;

export default hintSlice.reducer;
