import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { PaletteItemInterface } from 'store/reducers/palettes/types';
import { getActivePaletteId } from 'store/reducers/themes/getters';
import { getState } from 'store/utils';

export const getPalettesStore = createSelector(getState, (state) => state.palettes);

export const getPalettesLoading = createSelector(getPalettesStore, (state) => state.palettesLoading);

export const getPalettes = createSelector(getPalettesStore, (state) => state.palettes);
export const getServerStateOfPalettes = createSelector(getPalettesStore, (state) => state.serverStateOfPalettes);
export const getHasChangesOfPalettes = createSelector([getServerStateOfPalettes, getPalettes], (serverState, currentState) =>
  serverState === null ? false : !isEqual(serverState, currentState),
);

export const getPalettesAsArray = createSelector(getPalettes, (state) => Object.values(state) as PaletteItemInterface[]);

export const getPalettesById = (id: string) => createSelector(getPalettes, (palettes) => palettes[id]);

export const getActivePalette = createSelector([getState, getActivePaletteId], (state, activePaletteId) =>
  getPalettesById(activePaletteId || '')(state),
);

export const getPaletteColors = createSelector(getActivePalette, (activePalette) => {
  if (activePalette) {
    return activePalette.groups.reduce(
      (groups, { colors, id }) => ({
        ...groups,
        [id]: colors.reduce((colors, { id, hex, name }) => ({ ...colors, [id]: { hex, name } }), {}),
      }),
      {} as Record<string, Record<string, { hex: string; name: string }>>,
    );
  }

  return {};
});

export const getGroupNames = createSelector(getActivePalette, (activePalette) => {
  if (activePalette) {
    return activePalette.groups.reduce((groups, { id, name }) => ({ ...groups, [id]: name }), {} as Record<string, string>);
  }
});

export const getPriorityIdsOfActivePalette = createSelector(getActivePalette, (activePalette) => {
  if (activePalette) {
    const { basedOn, id } = activePalette;

    return [...(basedOn || []), id].reverse();
  }

  return [];
});
