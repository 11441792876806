import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';

export const loadPalettes = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectPalettesResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/palettes`);

/* TODO - Тут any убрать как только Леша добавить типы на Бэке */
export const createGeneratePalette = ({
  model,
  color,
  color_count,
}: {
  model: 'monochrome' | 'ML' | 'complementary';
  color: string[];
  color_count: number;
}) =>
  axiosClient.post<
    string,
    any,
    {
      model: 'monochrome' | 'ML' | 'complementary';
      color: string[];
      color_count: number;
    }
  >(`/paliitres/generate_pallet`, {
    model,
    color,
    color_count,
  });
