import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addWidgetGroupFunc,
  initialGroupsVisualisationsState,
  removeWidgetGroupFunc,
  updateViewSettingsWidgetGroupFunc,
  updateWidgetGroupFunc,
  updateWidgetGroupPositionFunc,
} from './constants';
import { RemoveGroupVisualisationsInterface, UpdateGroupWidgetIdsInterface, UpdateWidgetGroupsInterface } from './types';
import { mergeRightMapOfSetWithLeft } from 'utils/utils';
import { loadGroupsVisualisationsByPageIdAction } from './actions';
import isUndefined from 'lodash/isUndefined';

export const groupsVisualisationsSlice = createSlice({
  name: 'groups',
  initialState: initialGroupsVisualisationsState,
  reducers: {
    addWidgetGroup: addWidgetGroupFunc,

    repeatAddWidgetGroup: addWidgetGroupFunc,

    removeWidgetGroup: removeWidgetGroupFunc,

    repeatRemoveWidgetGroup: removeWidgetGroupFunc,

    updateOfIds: (state, { payload: { id, pageId, addWidget } }: PayloadAction<RemoveGroupVisualisationsInterface>) => {
      if (pageId && !isUndefined(addWidget)) {
        const setOfIds = state.groupsByPages[pageId] || new Set<string>();

        addWidget ? setOfIds.add(id) : setOfIds.delete(id);

        state.groupsByPages = { ...state.groupsByPages, [pageId]: setOfIds };
      }
    },

    updateWidgetGroups: (
      state,
      { payload: { widgetIds, isGrouped, parentGroupId } }: PayloadAction<UpdateWidgetGroupsInterface>,
    ) => {
      widgetIds?.forEach((widgetId) => {
        const group = state.widgetGroups[widgetId];

        if (group) {
          group.isGrouped = isGrouped;
          group.parentGroupId = parentGroupId;
        }
      });
    },

    updateGroupWidgetIds: (state, { payload: { widgetIds, groupId } }: PayloadAction<UpdateGroupWidgetIdsInterface>) => {
      const group = state.widgetGroups[groupId];

      if (group) {
        group.widgetIds = widgetIds;
      }
    },

    updateWidgetGroup: updateWidgetGroupFunc,

    repeatUpdateWidgetGroup: updateWidgetGroupFunc,

    updateViewSettingsWidgetGroup: updateViewSettingsWidgetGroupFunc,

    returnViewSettingsWidgetGroup: updateViewSettingsWidgetGroupFunc,

    updateWidgetGroupPosition: updateWidgetGroupPositionFunc,

    repeatUpdateWidgetGroupPosition: updateWidgetGroupPositionFunc,

    addToAlreadyLoadedByPageId: (state, { payload: pageId }: PayloadAction<string>) => {
      const currentState = state.alreadyLoadedContent.groups || new Set<string>();
      currentState.add(pageId);

      state.alreadyLoadedContent.groups = currentState;
    },

    resetAlreadyLoadedWidgetGroups: (state) => {
      state.alreadyLoadedContent.groups = new Set<string>();
      state.widgetGroups = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadGroupsVisualisationsByPageIdAction.fulfilled,
      (
        state,
        {
          payload: { groupsByPages, widgetGroups },
          meta: {
            arg: { pageId },
          },
        },
      ) => {
        const updatedWidgetGroups = { ...state.widgetGroups, ...widgetGroups };

        state.widgetGroups = updatedWidgetGroups;
        state.serverStateOfGroupsVisualisations = updatedWidgetGroups;

        const mergedGroupsVisualisationsByPages = mergeRightMapOfSetWithLeft(state.groupsByPages, groupsByPages);

        state.groupsByPages = { ...mergedGroupsVisualisationsByPages };

        const newAlreadyLoadedContent = state.alreadyLoadedContent.groups;
        newAlreadyLoadedContent.add(pageId);
        state.alreadyLoadedContent.groups = newAlreadyLoadedContent;
      },
    );
  },
});

export const {
  updateWidgetGroups,
  updateGroupWidgetIds,
  addWidgetGroup,
  updateWidgetGroupPosition,
  removeWidgetGroup,
  updateOfIds,
  updateWidgetGroup,
  addToAlreadyLoadedByPageId,
  updateViewSettingsWidgetGroup,
  returnViewSettingsWidgetGroup,
  repeatAddWidgetGroup,
  repeatRemoveWidgetGroup,
  repeatUpdateWidgetGroup,
  repeatUpdateWidgetGroupPosition,
  resetAlreadyLoadedWidgetGroups,
} = groupsVisualisationsSlice.actions;
export default groupsVisualisationsSlice.reducer;
