import styled from '@emotion/styled';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface PrimaryTextType {
  color?: string;
  fontWeight?: 'bold' | 'normal' | number | string;
  fontFamily?: string;
  fontStyle?: 'italic' | 'normal' | string;
  fontSize?: '12px' | '14px' | '16px' | '18px' | '20px' | '24px' | '28px' | '32px' | '40px' | '48px' | string;
  lineHeight?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  padding?: string;
  transition?: string;
  textDecoration?: 'underline';
  textTransform?: 'capitalize' | 'lowercase' | 'uppercase';
  whiteSpace?: 'nowrap' | 'pre' | 'normal' | 'pre-wrap';
  textAlign?: 'left' | 'center' | 'right' | string;
  textOverflow?: 'ellipsis';
  overflow?: 'hidden' | 'auto';
  width?: string;
  maxHeight?: string;
  maxWidth?: string;
  letterSpacing?: string;
  cursor?: string;
  position?: string;
  wordWrap?: 'break-word';
  opacity?: number;
  textWrap?: 'pretty' | 'balance';
  overflowWrap?: 'normal' | 'anywhere' | 'break-word';
  wordSpacing?: string;
  defaultFontFamily?: string | 'none' | 'inherit';
  hoverColor?: string;
}

export const PrimaryTextSpan = styled.span<PrimaryTextType>`
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  font-family: ${({ fontFamily, defaultFontFamily = "'Roboto', sans-serif" }) =>
    fontFamily ? `${fontFamily}` : defaultFontFamily};
  transition: ${(props) => props.transition || 'all 200ms'};
  font-size: ${(props) => props.fontSize || '12px'};
  line-height: ${(props) => props.lineHeight || '24px'};
  color: ${(props) => props.color || `var(${ColorVarsEnum.Level_2})`};
  margin-top: ${(props) => props.marginTop || '0'};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  padding: ${(props) => props.padding || '0'};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => props.whiteSpace};
  text-align: ${(props) => props.textAlign};
  text-overflow: ${(props) => props.textOverflow};
  overflow: ${(props) => props.overflow};
  max-width: ${(props) => props.maxWidth};
  letter-spacing: ${(props) => props.letterSpacing};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  width: ${(props) => props.width || 'auto'};
  word-wrap: ${(props) => props.wordWrap};
  opacity: ${(props) => props.opacity};
  text-wrap: ${(props) => props.textWrap};
  overflow-wrap: ${({ overflowWrap }) => overflowWrap};
  word-spacing: ${(props) => props.wordSpacing};
`;

export const PrimaryTextParagraph = styled.p<PrimaryTextType>`
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.fontWeight};
  font-family: ${({ fontFamily, defaultFontFamily = "'Roboto', sans-serif" }) =>
    fontFamily ? `${fontFamily}` : defaultFontFamily};
  font-size: ${(props) => props.fontSize || '12px'};
  line-height: ${(props) => props.lineHeight || '100%'};
  transition: ${(props) => props.transition || 'all 200ms'};
  color: ${(props) => props.color || `var(${ColorVarsEnum.Level_3})`};
  margin-top: ${(props) => props.marginTop || '0'};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  margin-left: ${(props) => props.marginLeft};
  padding: ${(props) => props.padding || 0};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => props.whiteSpace};
  text-align: ${(props) => props.textAlign};
  text-overflow: ${(props) => props.textOverflow};
  overflow: ${(props) => props.overflow};
  width: ${(props) => props.width};
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  width: ${(props) => props.width || 'auto'};
  word-wrap: ${(props) => props.wordWrap};
  text-wrap: ${(props) => props.textWrap};
  overflow-wrap: ${({ overflowWrap }) => overflowWrap};
`;

export const TextLink = styled.a<PrimaryTextType>`
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  font-family: ${({ fontFamily, defaultFontFamily = "'Roboto', sans-serif" }) =>
    fontFamily ? `${fontFamily}` : defaultFontFamily};
  transition: ${(props) => props.transition || 'all 200ms'};
  font-size: ${(props) => props.fontSize || '12px'};
  line-height: ${(props) => props.lineHeight || '24px'};
  color: ${(props) => props.color || `var(${ColorVarsEnum.Level_2})`};
  margin-top: ${(props) => props.marginTop || '0'};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  padding: ${(props) => props.padding || '0'};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => props.whiteSpace};
  text-align: ${(props) => props.textAlign};
  text-overflow: ${(props) => props.textOverflow};
  overflow: ${(props) => props.overflow};
  max-width: ${(props) => props.maxWidth};
  letter-spacing: ${(props) => props.letterSpacing};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  width: ${(props) => props.width || 'auto'};
  word-wrap: ${(props) => props.wordWrap};
  text-decoration: none;

  :hover {
    text-decoration: none;
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : `var(${ColorVarsEnum.Accent})`)};
  }
`;
