import { AdminSettingsSliceStateInterface } from 'store/reducers/adminSettings/types';

export const initialAdminSettingsStoreState: AdminSettingsSliceStateInterface = {
  versionStrategy: {
    versionStrategyData: null,
    loading: false,
  },
  fontSettings: {
    loading: false,
    fontSettingsData: {
      isActive: true,
      fontFamily: '',
      fontWeight: '400',
      fontStyle: 'normal',
    },
  },
  fontsProjectSettings: {
    loading: false,
    fontsProjectSettingsList: [],
    currentFont: '',
  },
};

/* Action Types */
export enum AdminSettingsActionsTypes {
  LOAD_VERSION_STRATEGY = 'LOAD_VERSION_STRATEGY',
  UPDATE_VERSION_STRATEGY = 'UPDATE_VERSION_STRATEGY',
  SET_VERSION_STRATEGY = 'SET_VERSION_STRATEGY',
  UPDATE_FONTS_SETTINGS = 'UPDATE_FONTS_SETTINGS',
  LOAD_ADMIN_FONTS_SETTINGS = 'LOAD_ADMIN_FONTS_SETTINGS',
  UPDATE_PROJECT_FONTS = 'UPDATE_PROJECT_FONTS',
  DELETE_FONTS = 'DELETE_FONTS',
  UPDATE_CURRENT_FONT = 'UPDATE_CURRENT_FONT',
}
