import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';

export const loadVersionStrategy = (): Promise<AxiosResponse<FastBoard.API.SettingsDeleteOldIncomingResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/settings/delete-old-incoming`);

export const loadAdminFontsProjectSettings = (): Promise<AxiosResponse<FastBoard.API.ApiAdminFontsResponseDTO>> =>
  axiosClient.get(`/api/v3/admin/fonts`);

export const updateAdminFontsProjectSettings = (font: string): Promise<AxiosResponse<FastBoard.API.ApiAdminFontsResponseDTO>> =>
  axiosClient.put(`/api/v3/admin/fonts`, { font });

export const updateVersionStrategy = (
  setting: FastBoard.API.SettingsDeleteOldIncomingTypeTimeDTO | FastBoard.API.SettingsDeleteOldIncomingTypeItemsDTO,
) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.SettingsDeleteOldIncomingResponseDTO>,
    FastBoard.API.SettingsDeleteOldIncomingUpdateDTO
  >(`/api/v2/admin/settings/delete-old-incoming`, { setting });

export const uploadFontsFiles = (files: FormData) =>
  axiosClient.post<string, AxiosResponse<FastBoard.API.ApiAdminFontsResponseDTO>, FormData>(`/api/v3/admin/fonts/create`, files);

export const deleteFonts = ({ fonts }: FastBoard.API.ApiAdminFontsDeleteDTO) =>
  axiosClient.delete<string, AxiosResponse<FastBoard.API.ApiAdminFontsResponseDTO>, FastBoard.API.ApiAdminFontsDeleteDTO>(
    `/api/v3/admin/fonts`,
    {
      data: { fonts },
    },
  );
