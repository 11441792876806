import {
  ProjectSettingsInterface,
  ProjectSettingsStateInterface,
  UpdateProjectSettingsPayload,
} from 'store/reducers/projectSettings/types';
import { PayloadAction } from '@reduxjs/toolkit';

export const defaultProjectSettings: ProjectSettingsInterface = {
  isViewMode: false,
  realData: {
    isForce: false,
    isShow: false,
  },
  gridSpacing: 10,
  isShowWorkSpace: false,
  scaleOfPage: {
    isActive: true,
    width: 1024,
  },
  font: {
    isShow: false,
    name: 'Roboto',
  },
  // fontSettings: {
  //   loading: false,
  //   fontSettingsData: {
  //     isActive: true,
  //     fontFamily: 'Roboto',
  //     fontWeight: '400',
  //     fontStyle: 'normal',
  //   },
  // },
  /*TODO: Comment on RLS and wait for the server */
  // rls: {
  //   isActive: false,
  //   entityId: null,
  //   type: 'user',
  // },
};

export const initialProjectSettingsState: ProjectSettingsStateInterface = {
  defaultModelId: null,
  serverStateOfDefaultModelId: null,
  defaultModelLoading: false,
  projectSettings: defaultProjectSettings,
  serverStateOfProjectSettings: null,
  projectSettingsLoading: false,
  dashboardComponentsLoading: false,
  dashboardComponentsLoaded: false,
  dashboardComponentsRendering: false,
  dashboardElementsLoading: false,
  themeSettingsLoading: false,
  saveInProgress: false,
  loadInProgress: false,
  lastSettingTimestamp: null,
  projectTask: {
    loading: false,
    task: null,
  },
  fontsProjectSettings: {
    loading: false,
    fontsProjectSettingsList: [],
    currentFont: 'Roboto',
  },
};

export const updateProjectSettingsFunc = (
  state: ProjectSettingsStateInterface,
  { payload }: PayloadAction<UpdateProjectSettingsPayload>,
) => {
  state.projectSettings = {
    ...state.projectSettings,
    ...payload,
  };
};
