import {
  FieldSettingsAreaLayout,
  HeaderSettingsWrapper,
  HeaderSettingsWrapperProps,
} from 'modules/settingsContainer/FieldSettingsArea/styles';
import { FC, NoopType } from 'types/global';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { Button } from 'modules/ui';
import { ClearIcon } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import React from 'react';
import { settingsTabHeight } from 'modules/ui/tabs/SettingsTabs/constants';
import { settingsLayoutWidth } from 'modules/settingsContainer/SettingsLayout/constants';

export interface HeaderSettingsAreaLayoutProps extends HeaderSettingsWrapperProps {
  headerText: string;
  onClose: NoopType;
  id?: string;
  backgroundColor?: string;
  needBackgroundButton?: boolean;
  borderBottom?: string;
}

export const HeaderSettingsAreaLayout: FC<HeaderSettingsAreaLayoutProps> = ({
  headerText,
  children,
  borderBottom,
  onClose,
  needBackgroundButton = true,
  backgroundColor = `var(${ColorVarsEnum.Level_2_btn})`,
  id,
  ...headerProps
}) => (
  <HeaderSettingsWrapper id={id} {...headerProps}>
    <FlexContainer
      padding="0  4px 0 12px"
      height={settingsTabHeight}
      backgroundColor={backgroundColor}
      alignItems="center"
      justifyContent="space-between"
      borderBottom={borderBottom}
    >
      <PrimaryTextSpan fontWeight="bold" lineHeight="12px">
        {headerText}
      </PrimaryTextSpan>
      <Button needBackground={needBackgroundButton} leftIcon={<ClearIcon />} heightSize="small" onClick={onClose} />
    </FlexContainer>
    {children}
  </HeaderSettingsWrapper>
);

type FieldSettingsAreaProps = Omit<HeaderSettingsAreaLayoutProps, 'headerText'>;

export const FieldSettingsArea: FC<FieldSettingsAreaProps> = ({ children, ...headerProps }) => {
  return (
    <FieldSettingsAreaLayout right={settingsLayoutWidth}>
      <HeaderSettingsAreaLayout width={settingsLayoutWidth} headerText="Настроить" {...headerProps}>
        {children}
      </HeaderSettingsAreaLayout>
    </FieldSettingsAreaLayout>
  );
};
