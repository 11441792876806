import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { getRolesListEnum, getUser, login, logout, updateLicenseStatus } from 'store/reducers/auth/actions';
import { defaultRolesListEntity, defaultUserEntity, initialState } from 'store/reducers/auth/types';
import { getState } from 'store/utils';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.token.loading = true;
      state.token.loaded = false;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.token.entity = payload;
      state.token.loading = false;
      state.token.loaded = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.token.loading = false;
      state.token.error = action.error.message;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.user.entity = defaultUserEntity;
      state.user.loading = false;
      state.user.loaded = false;
      state.user.timestampState = null;
      state.token.loading = false;
      state.token.loaded = false;
      state.token.entity = null;
    });

    builder.addCase(getRolesListEnum.pending, (state) => {
      state.rolesList.entity = defaultRolesListEntity;
      state.rolesList.loading = true;
      state.rolesList.loaded = false;
    });
    builder.addCase(getRolesListEnum.fulfilled, (state, { payload }) => {
      if (payload && !isEmpty(payload)) {
        state.rolesList.entity = payload;
        state.rolesList.timestampState = Date.now();
      }
      state.rolesList.loading = false;
      state.rolesList.loaded = true;
    });
    builder.addCase(getRolesListEnum.rejected, (state, action) => {
      state.rolesList.loading = false;
      state.rolesList.error = action.error.message;
      state.rolesList.error = action.error.message;
    });

    builder.addCase(getUser.pending, (state) => {
      if (state.user.loaded) {
        state.user.entity = defaultUserEntity;
      }
      state.user.loading = true;
      state.user.loaded = false;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      if (payload && !isEmpty(payload)) {
        state.user.entity = payload;
        state.user.timestampState = Date.now();
      }
      state.user.loading = false;
      state.user.loaded = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.user.loading = false;
      state.user.error = action.error.message;
    });
    builder.addCase(updateLicenseStatus.fulfilled, (state, { payload }) => {
      if (state.user.entity?.license) {
        state.user.entity.license.status = payload;
      }
    });
  },
});

export default authSlice.reducer;
export const getAuthStore = createSelector(getState, (state) => state.auth);
export const getTokenData = createSelector(getAuthStore, (auth) => auth.token);
export const getAuthError = createSelector(getTokenData, (token) => token.error);
export const getToken = createSelector(getTokenData, (token) => token.entity);

export const getRolesListData = createSelector(getAuthStore, (state) => state.rolesList);
export const getRolesList = createSelector(getRolesListData, (rolesList) => rolesList.entity.roles);
export const getRolesListForSelect = createSelector(getRolesList, (rolesList) =>
  rolesList.map(({ title, id }) => ({ name: title, value: id })),
);

export const getRolesListLoaded = createSelector(getRolesListData, (rolesList) => rolesList.loaded);
export const getRolesListTimestampState = createSelector(getRolesListData, (rolesList) => rolesList.timestampState);

export const getUserData = createSelector(getAuthStore, (auth) => auth.user);
export const getUserInfo = createSelector(getUserData, (user) => user.entity);
export const getUserRole = createSelector(getUserInfo, (user) => user.role);
export const getUserLoaded = createSelector(getUserData, (user) => user.loaded);
export const getUserLoading = createSelector(getUserData, (user) => user.loading);
export const getUserTimestampState = createSelector(getUserData, (user) => user.timestampState);
