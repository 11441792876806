import styled from 'styled-components';
import { DownIcon } from 'assets/icons/withContainer';
import { IsActiveInterface } from 'store/reducers/visualisations/types';

export const ArrowIcon = styled(DownIcon)<IsActiveInterface>`
  cursor: pointer;
  height: 14px;
  width: 14px;
  transition: transform 200ms;
  transform: ${({ isActive }) => (isActive ? 'rotate(0deg)' : 'rotate(180deg)')};
`;
