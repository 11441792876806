import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialVisualSettingsStoreState } from 'store/reducers/visualSettings/constants';
import { OpenSettingsComponentType, VisualSettingsComponentType } from 'store/reducers/visualSettings/types';

export const visualSettingsSlice = createSlice({
  name: 'visualSettings',
  initialState: initialVisualSettingsStoreState,
  reducers: {
    setSettingComponent: (state, { payload: SettingComponent }: PayloadAction<VisualSettingsComponentType>) => {
      state.SettingComponent = SettingComponent;
    },
    openSettingsComponent: (state, { payload: type }: PayloadAction<OpenSettingsComponentType>) => {
      if (state.openSettingsComponents[type]) {
        state.openSettingsComponents[type].isOpen = true;
      }
    },
    visibilitySettingsAllComponent: (state) => {
      state.visibilityAllSettings = !state.visibilityAllSettings;
    },
    closeSettingsComponent: (state, { payload: type }: PayloadAction<OpenSettingsComponentType>) => {
      if (state.openSettingsComponents[type]) {
        state.openSettingsComponents[type].isOpen = false;
      }
    },
    toggleSettingsComponent: (state, { payload: type }: PayloadAction<OpenSettingsComponentType>) => {
      if (state.openSettingsComponents[type]) {
        state.openSettingsComponents[type].isOpen = !state.openSettingsComponents[type].isOpen;
      }
    },
  },
});

export const {
  setSettingComponent,
  openSettingsComponent,
  visibilitySettingsAllComponent,
  closeSettingsComponent,
  toggleSettingsComponent,
} = visualSettingsSlice.actions;

export default visualSettingsSlice.reducer;
