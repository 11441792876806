import styled, { css } from 'styled-components';
import { defaultColorSize } from 'modules/settingsContainer/ColorPicker/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ShapeEnum, ShapeType } from 'components/shared/ColorTransparent/types';

export interface ColorGridProps {
  color: string;
  active?: boolean;
  colorSize?: number;
  shape?: ShapeType;
  gap?: number;
}

export const ColorGrid = styled.div<ColorGridProps>`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: ${({ colorSize }) => `${colorSize || defaultColorSize}px`};
  width: ${({ colorSize }) => `${colorSize || defaultColorSize}px`};
  background-color: ${({ color }) => color || 'unset'};
  cursor: pointer;
  transition: 0.2s;
  border-radius: ${({ shape }) => (shape === ShapeEnum.CIRCLE ? '50%' : '0')};
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  overflow: hidden;

  & > * {
    box-sizing: border-box;
    width: calc(50% - ${({ gap }) => (gap ? `${gap / 2}px` : '0px')});
    height: calc(50% - ${({ gap }) => (gap ? `${gap / 2}px` : '0px')});
  }

  :hover {
    transform: scale(1.1);
    ${({ shape }) => (shape === ShapeEnum.CIRCLE ? '50%' : '0')};
  }

  ${({ active, shape }) =>
    active &&
    css`
      border: 2px solid var(${ColorVarsEnum.Level_1});
      transform: scale(1.1);
      ${shape === ShapeEnum.CIRCLE ? '50%' : '0'};
    `}
`;
