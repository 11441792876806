import { ClickAwayListener, Popover } from '@mui/material';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isEqual from 'lodash/isEqual';
import { colorPickerClassName, colorSelectorBlockSize } from 'modules/settingsContainer/ColorPicker/constants';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import {
  ColorBlock,
  ColorPickerWrapper,
  ColorSelector,
  GroupNameTextSpan,
  Groups,
  GroupsWrapper,
  Palette,
} from 'modules/settingsContainer/ColorPicker/styles';
import {
  ActivatorProps,
  ActivatorType,
  ColorClickValue,
  ColorPickerTypesInterface,
  ColorValue,
  ColorValuesByThemeType,
  ColorValueType,
  LevelNumbersType,
  PaletteValue,
  PaletteValuesByThemeType,
  PaletteValueType,
  PositionIndexType,
  ThemeColorValue,
} from 'modules/settingsContainer/ColorPicker/types';
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { ColorTransparent } from 'components/shared/ColorTransparent';

interface OriginCircleActivatorProps extends ActivatorProps {
  width?: string;
  height?: string;
}

export const OriginCircleActivator = ({
  width = '24px',
  height = '24px',
  onClick,
  colorValues,
  defaultBackgroundColor,
}: OriginCircleActivatorProps) => {
  const { activeThemeSchema } = useColorValues();

  const backgroundColor = useMemo(
    () =>
      typeof colorValues === 'string'
        ? colorValues
        : colorValues?.[0] || defaultBackgroundColor || activeThemeSchema[ColorVarsEnum.Level_2_btn],
    [colorValues, defaultBackgroundColor, activeThemeSchema],
  );

  return (
    <ColorSelector onClick={onClick} width={width} height={height}>
      <ColorTransparent disabledDeleteIcon color={backgroundColor} colorSize={+width.slice(0, 2)} />
    </ColorSelector>
  );
};

export const CircleActivator: ActivatorType = (props) => <OriginCircleActivator {...props} />;

export const LargeCircleActivator: ActivatorType = (props) => <OriginCircleActivator width="30px" height="30px" {...props} />;

const DefaultActivator: ActivatorType = ({ colorValues, onClick, defaultBackgroundColor, isHiddenActivatorColor }) => {
  const activeColors = useMemo(
    () => (typeof colorValues === 'string' ? [colorValues] : defaultBackgroundColor ? [defaultBackgroundColor] : colorValues),
    [colorValues, defaultBackgroundColor],
  );

  const colorBlocks = useMemo(() => {
    if (activeColors) {
      const [first, second, third, fourth] = activeColors,
        secondColor = second || first,
        thirdColor = third || secondColor,
        fourthColor = fourth || thirdColor;

      return [first, secondColor, thirdColor, fourthColor];
    }

    return [];
  }, [activeColors]);

  return (
    <ColorSelector onClick={onClick}>
      {colorBlocks.map((color, positionIndex) => (
        <ColorBlock key={`${color}_${positionIndex}`} positionIndex={positionIndex as PositionIndexType}>
          <ColorTransparent disabledDeleteIcon color={isHiddenActivatorColor ? '' : color} colorSize={colorSelectorBlockSize} />
        </ColorBlock>
      ))}
    </ColorSelector>
  );
};

export interface ColorPickerProps<Value extends PaletteValuesByThemeType | ColorValuesByThemeType = ColorValuesByThemeType>
  extends ColorPickerTypesInterface {
  disabled?: boolean;
  onChange?: (value: Value | null) => void;
  value?: Value | null;
  closeOnClick?: boolean;
  Activator?: ActivatorType;
  defaultActivatorBackgroundColor?: string;
  isHiddenActivatorColor?: boolean;
}

export const ColorPicker = <Value extends PaletteValuesByThemeType | ColorValuesByThemeType = ColorValuesByThemeType>({
  disabled,
  onChange,
  value,
  type = 'color',
  closeOnClick = false,
  defaultActivatorBackgroundColor,
  isHiddenActivatorColor,
  Activator = DefaultActivator,
}: ColorPickerProps<Value>) => {
  const {
    paletteId,
    getHighPriorityColorValue,
    activePaletteColor,
    defaultThemePalette,
    getColorValues,
    groupNames,
    showGroupNames,
  } = useColorValues();

  if (!paletteId) return null;

  const paletteGroups = useMemo(
    () => Object.entries(activePaletteColor).map(([groupId, colors]) => ({ groupId, colors })),
    [activePaletteColor],
  );

  const isColorPicker = type === 'color';

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeColorPickerValue, setActiveColorPickerValue] = useState<Value | null>(null);
  const activePaletteColorPickerValue = getHighPriorityColorValue(activeColorPickerValue);

  const onColorSelectorClick: MouseEventHandler<HTMLDivElement> = (e) => setPopoverAnchorEl(e.currentTarget);
  const onCloseMenu = () => setPopoverAnchorEl(null);

  const onLocalChange = useCallback(
    (newValue: Value) => {
      setActiveColorPickerValue(newValue);
      onChange && onChange(newValue);
    },
    [onChange],
  );

  const setValue = useCallback(
    <T extends ColorValueType | PaletteValueType>(value: T) => {
      const oldValue = activePaletteColorPickerValue,
        newValue = isEqual(oldValue, value) ? undefined : value;

      onLocalChange({ ...(activeColorPickerValue || {}), [paletteId]: newValue } as Value);
      closeOnClick && onCloseMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeColorPickerValue, closeOnClick, onLocalChange],
  );

  const onColorClick = useCallback(
    (colorValue: ColorClickValue) => {
      if (isColorPicker) {
        let value;

        if (typeof colorValue === 'number') {
          value = { type, level: colorValue };
        } else {
          const { groupId, colorId } = colorValue;
          value = { groupId, colorId, type };
        }

        setValue(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isColorPicker, paletteId, type, setValue],
  );

  const onGroupClick = useCallback(
    (groupId?: string) =>
      isColorPicker
        ? undefined
        : () => {
            let value;

            if (groupId) {
              value = { groupId, type };
            } else {
              value = { type };
            }

            setValue(value);
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isColorPicker, paletteId, type, setValue],
  );

  useEffect(() => {
    value && setActiveColorPickerValue(value);
  }, [value]);

  return (
    <ColorPickerWrapper>
      <Activator
        defaultBackgroundColor={defaultActivatorBackgroundColor}
        colorValues={getColorValues(activeColorPickerValue)}
        onClick={disabled ? undefined : onColorSelectorClick}
        isHiddenActivatorColor={isHiddenActivatorColor}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={popoverAnchorEl}
        open={!!popoverAnchorEl}
        className={colorPickerClassName}
      >
        <ClickAwayListener onClickAway={onCloseMenu}>
          <Palette type={type}>
            {paletteGroups.map(({ groupId, colors }) => {
              const groupColors = Object.entries(colors),
                isActiveGroup =
                  !isColorPicker && (activePaletteColorPickerValue as PaletteValue | undefined)?.groupId === groupId,
                groupName = groupNames?.[groupId];

              return (
                <GroupsWrapper active={isActiveGroup} onClick={onGroupClick(groupId)} key={groupId}>
                  {groupName && showGroupNames && <GroupNameTextSpan>{groupName}</GroupNameTextSpan>}
                  <Groups>
                    {groupColors.map(([colorId, { name, hex }]) => {
                      const isActiveColor =
                        isColorPicker && (activePaletteColorPickerValue as ColorValue | undefined)?.colorId === colorId;

                      return (
                        <ColorTransparent
                          disabledDeleteIcon
                          active={isActiveColor}
                          onClick={() => onColorClick({ colorId, groupId })}
                          key={colorId}
                          color={hex}
                          name={name}
                        />
                      );
                    })}
                  </Groups>
                </GroupsWrapper>
              );
            })}

            <GroupsWrapper active={!isColorPicker && isEqual(activePaletteColorPickerValue, { type })} onClick={onGroupClick()}>
              {showGroupNames && <GroupNameTextSpan>Стандартная группа</GroupNameTextSpan>}
              <Groups>
                {defaultThemePalette.map((color, index) => {
                  const level = index + 1,
                    isActiveColor =
                      isColorPicker && (activePaletteColorPickerValue as ThemeColorValue | undefined)?.level === level;

                  return (
                    <ColorTransparent
                      disabledDeleteIcon
                      active={isActiveColor}
                      onClick={() => onColorClick(level as LevelNumbersType)}
                      key={`${color}_${index}`}
                      color={color}
                    />
                  );
                })}
              </Groups>
            </GroupsWrapper>
          </Palette>
        </ClickAwayListener>
      </Popover>
    </ColorPickerWrapper>
  );
};
