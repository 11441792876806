import { Menu, MenuItem } from '@mui/material';
import { PointsMenuIcon } from 'assets/icons/menu';
import { IconType, SuccessIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import React, { useEffect, useState } from 'react';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { NoopValueType } from 'types/global';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { FlexContainer } from 'styles/FlexContainer';
import { MENU_LIST_COMPONENT } from 'modules/workspace/constans';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { WrapperContainer } from './styles';
import { ShortFilter, ShortFilterProps } from 'assets/icons/filter/ShortFilter';

interface MenuListComponentProps<T extends string = string> extends ShortFilterProps {
  options: Option<T>[];
  onSelect?: NoopValueType<T>;
  selected?: T | null;
  Icon?: IconType;
  titleIcon?: string;
  allowToggle?: boolean;
  sortWithClick?: boolean;
}

export const MenuListComponent = <T extends string>({
  options,
  selected,
  onSelect,
  Icon = PointsMenuIcon,
  allowToggle,
  sortingStatus,
  titleIcon,
  onSortingClick,
  sortWithClick,
}: MenuListComponentProps<T>) => {
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);
  const open = Boolean(anchorPosition);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorPosition(anchorPosition ? null : { top: event.clientY, left: event.clientX });
  };

  const onSelectValue = (value: T) => {
    if (onSelect) {
      const selectValue = allowToggle && selected === value ? ('' as T) : value;

      onSelect(selectValue);
    }
  };

  const handleClose = () => {
    setAnchorPosition(null);
  };

  useEffect(() => {
    const onClickAway = (event: MouseEvent) => {
      if (
        anchorPosition &&
        event.target instanceof Node &&
        !document.elementFromPoint(anchorPosition.left, anchorPosition.top)?.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', onClickAway);
    return () => {
      document.removeEventListener('mousedown', onClickAway);
    };
  }, [anchorPosition]);

  return (
    <div>
      <StyledTooltip title={titleIcon}>
        <>
          {allowToggle ? (
            sortWithClick ? (
              <ShortFilter sortingStatus={sortingStatus} onSortingClick={onSortingClick} />
            ) : (
              <WrapperContainer>
                <IconWrapper Icon={Icon} onClick={onClick} disableDefaultStyles />
              </WrapperContainer>
            )
          ) : (
            <IconWrapper
              hoverColorVar={ColorVarsEnum.Level_3}
              colorVar={ColorVarsEnum.Level_3}
              containerWidth="20px"
              containerHeight="20px"
              iconHeight="20px"
              iconWidth="20px"
              Icon={Icon}
              onClick={onClick}
            />
          )}
        </>
      </StyledTooltip>
      <Menu
        id={MENU_LIST_COMPONENT}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition ? { top: anchorPosition.top, left: anchorPosition.left } : undefined}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '31ch',
            zIndex: 125,
          },
        }}
      >
        {options.map(({ value, name, onClick, disabled, Icon }) => (
          <MenuItem
            hidden={disabled}
            style={{ backgroundColor: value === selected ? `var(${ColorVarsEnum.Level_3_menu})` : undefined }}
            key={`${name}${value}`}
            selected={value === selected}
            onClick={(e) => {
              onSelect ? onSelectValue(value) : onClick && onClick();
              e.stopPropagation();
            }}
          >
            <FlexContainer flexDirection="row" alignItems="center" gap="8px" width="100%">
              {Icon && <IconWrapper iconWidth="20px" iconHeight="20px" Icon={Icon} />}
              {selected && (
                <div style={{ opacity: value === selected ? 1 : 0 }}>
                  <IconWrapper iconWidth="10px" iconHeight="10px" Icon={SuccessIcon} />
                </div>
              )}
              <PrimaryTextParagraph
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                lineHeight="14px"
                fontSize="14px"
                position="relative"
                color={selected ? `var(${ColorVarsEnum.Level_1})` : `var(${ColorVarsEnum.Level_2})`}
              >
                {name}
              </PrimaryTextParagraph>
            </FlexContainer>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
