import {
  GroupWidgetSettingsInterface,
  initialGroupsStateInterface,
  RemoveGroupVisualisationsInterface,
  UpdateGroupVisualisationsInterface,
  UpdateViewSettingsGroupVisualisationsInterface,
  WidgetGroupInterface,
} from 'store/reducers/groupsVisualisations/types';
import { GroupTypeEnum } from 'store/reducers/filters/types';
import { defaultViewSettings } from 'store/reducers/visualisations/constants';
import { defaultGroupStylesViewSettings } from 'constants/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { UpdatePositionConfigPayload } from 'store/reducers/visualisations/types';

export const initialGroupsVisualisationsState: initialGroupsStateInterface = {
  widgetGroups: {},
  serverStateOfGroupsVisualisations: null,
  groupsByPages: {},
  alreadyLoadedContent: {
    groups: new Set<string>(),
  },
};

export const getInitialGroup = ({ widgetGroup }: { widgetGroup: WidgetGroupInterface }): GroupWidgetSettingsInterface => {
  const { id, name, positionConfig, widgetIds, pageId, parentGroupId, isGrouped } = widgetGroup;

  return {
    id,
    positionConfig,
    widgetIds,
    pageId,
    parentGroupId,
    isVisible: true,
    type: GroupTypeEnum.GROUP,
    isGrouped,
    viewSettings: {
      ...defaultViewSettings,
      ...defaultGroupStylesViewSettings,
      backgroundSettings: {
        ...defaultViewSettings.backgroundSettings,
        colorSettings: {
          ...defaultViewSettings.backgroundSettings.colorSettings,
          background: {
            isShow: false,
            color: null,
          },
        },
        isShow: false,
      },
      paddingSettings: {
        ...defaultViewSettings.paddingSettings,
        gapX: 20,
        gapY: 20,
      },
      name,
    },
  };
};

export const updateViewSettingsWidgetGroupFunc = (
  state: initialGroupsStateInterface,
  { payload: { id, viewSettings } }: PayloadAction<UpdateViewSettingsGroupVisualisationsInterface>,
) => {
  const group = state.widgetGroups[id];

  if (group) {
    group.viewSettings = { ...group.viewSettings, ...viewSettings };
  }
};

export const addWidgetGroupFunc = (
  state: initialGroupsStateInterface,
  { payload: newGroup }: PayloadAction<GroupWidgetSettingsInterface>,
) => {
  state.widgetGroups = { ...state.widgetGroups, [newGroup.id]: newGroup };
};

export const removeWidgetGroupFunc = (
  state: initialGroupsStateInterface,
  { payload: { id } }: PayloadAction<RemoveGroupVisualisationsInterface>,
) => {
  const pageId = state.widgetGroups[id]?.pageId;

  if (pageId) {
    delete state.widgetGroups[id];

    const setOfIds = state.groupsByPages[pageId] || new Set<string>();
    setOfIds.delete(id);

    state.groupsByPages = { ...state.groupsByPages, [pageId]: setOfIds };
  }
};

export const updateWidgetGroupFunc = (
  state: initialGroupsStateInterface,
  { payload: { id, settings } }: PayloadAction<UpdateGroupVisualisationsInterface>,
) => {
  const group = state.widgetGroups[id];

  if (group) {
    state.widgetGroups[id] = { ...group, ...settings };
  }
};

export const updateWidgetGroupPositionFunc = (
  state: initialGroupsStateInterface,
  { payload: { id, positionConfig } }: PayloadAction<UpdatePositionConfigPayload>,
) => {
  const group = state.widgetGroups[id];

  if (group) {
    group.positionConfig = { ...group.positionConfig, ...positionConfig };
  }
};
