export const DELETE_BUTTONS = ['Backspace', 'Delete'];

export const ACTIVE_BOARD_AREA_LABEL = 'active-board-area';
export const ACTIVE_BOARD_AREA_LABEL_IN_VIEW_MODE = 'active-board-area-label-in-view-mode';
export const SETTINGS_BUTTON_LABEL = 'settings-button';
export const SQL_BUTTON_LABEL = 'sql-button';
export const ERROR_BOARD_AREA_LABEL = 'error-board-area';
export const SHOW_MODE = 'show-mode';
export const SCROLL_TO_TOP = 'scroll-to-top';
export const ACTIVE_MODEL_TABLE_AREA_CLASS = 'active-model-table-area';
export const TAG_TEXTAREA = 'TEXTAREA';
export const TAG_INPUT = 'INPUT';
export const TOOLTIP = 'tooltip-open';
export const CONTEXT_MENU = 'context-menu';
export const SELECTOR_MENU = 'selector-menu';
export const MODAL_UNIVERSAL = 'modal-universal';
export const MENU_LIST_COMPONENT = 'menu-list-component';
export const MENU_PAGE_LIST_COMPONENT = 'menu-page-list-component';

export const isCopyHotkey = (e: KeyboardEvent) => {
  return e.code == 'KeyC' && (e.ctrlKey || e.metaKey);
};

export const isCutHotkey = (e: KeyboardEvent) => {
  return e.code == 'KeyX' && (e.ctrlKey || e.metaKey);
};

export const isDeleteHotkey = (e: KeyboardEvent) => {
  return DELETE_BUTTONS.includes(e.key);
};

export const isPasteHotkey = (e: KeyboardEvent) => {
  return e.code == 'KeyV' && (e.ctrlKey || e.metaKey);
};

export const isToTopLayerHotkey = (e: KeyboardEvent) => {
  return e.code == 'BracketLeft' && !(e.ctrlKey || e.metaKey);
};

export const isToBottomLayerHotkey = (e: KeyboardEvent) => {
  return e.code == 'BracketRight' && !(e.ctrlKey || e.metaKey);
};

export const isUpLayerHotkey = (e: KeyboardEvent) => {
  return e.code == 'BracketLeft' && (e.ctrlKey || e.metaKey);
};

export const isDownLayerHotkey = (e: KeyboardEvent) => {
  return e.code == 'BracketRight' && (e.ctrlKey || e.metaKey);
};

export const isToggleViewModeHotkey = (e: KeyboardEvent) => {
  return e.code == 'KeyY' && e.altKey;
};

export const isStepUndo = (e: KeyboardEvent) => {
  return e.code == 'KeyZ' && !e.shiftKey && (e.ctrlKey || e.metaKey);
};

export const isStepRedo = (e: KeyboardEvent) => {
  return (e.code == 'KeyZ' && e.shiftKey && (e.ctrlKey || e.metaKey)) || (e.code == 'KeyY' && (e.ctrlKey || e.metaKey));
};

export const isUpKeyz = (e: KeyboardEvent) => {
  return e.code == 'ArrowUp';
};

export const isDownKeyz = (e: KeyboardEvent) => {
  return e.code == 'ArrowDown';
};

export const isLeftKeyz = (e: KeyboardEvent) => {
  return e.code == 'ArrowLeft';
};

export const isVisibilitySettingsComponent = (e: KeyboardEvent) => {
  return e.code == 'Backquote';
};

export const isRightKeyz = (e: KeyboardEvent) => {
  return e.code == 'ArrowRight';
};

export const isAddWidgetGroupKeyz = (e: KeyboardEvent) => {
  return e.code == 'KeyG' && (e.ctrlKey || e.metaKey) && !e.shiftKey;
};

export const isRemoveWidgetGroupKeyz = (e: KeyboardEvent) => {
  return e.code === 'KeyG' && (e.ctrlKey || e.metaKey) && e.shiftKey;
};

export const isBlockWidgetHotkey = (e: KeyboardEvent) => {
  return e.code == 'KeyL' && (e.ctrlKey || e.metaKey);
};
