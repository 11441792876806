export const ServerCode = {
  success: 200,
  ValidationError: 400,
  AuthError: 401,
  MethodNotAllowed: 404,
  InternalServerError: 500,
  ActivationKeyServerError: 430,
} as const;

export const ServerError = {
  ValidationError: 'Data error',
  AuthError: 'Not authorized',
  MethodNotAllowed: 'Method does not exist',
  InternalServerError: 'Backend is not responding',
} as const;

export const serverErrorText = {
  [ServerCode.ValidationError]: 'Ошибка данных',
  [ServerCode.AuthError]: 'Авторизуйся',
  [ServerCode.MethodNotAllowed]: 'Метод не существует',
  [ServerCode.InternalServerError]: 'Не отвечает сервер',
};
