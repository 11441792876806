import { Container, IProps, SelectItemInterface, useOpenSelector } from 'modules/ui/Select';
import { ClickAwayListener, MenuItem, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { StyledCombobox } from './styles';
import { DownIcon } from 'assets/icons/withContainer';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import Popper, { PopperProps } from '@mui/material/Popper/Popper';
import { SELECTOR_MENU } from 'modules/workspace/constans';

export interface ComboboxProps extends IProps {
  disableClearable?: boolean;
  tooltipDisableHoverListener?: boolean;
  minWidth?: string;
}

/*TODO: Надо будет этот компонент улучшить, виделить время и сделать*/
export const Combobox = ({
  name,
  disabled = false,
  tooltipDisableHoverListener = false,
  heightSize = 'normal',
  needBackground = true,
  visibleElements = 10,
  width = '190px',
  onChange,
  options,
  value,
  multiple = false,
  title = '',
  placeholder = '',
  disableClearable,
  minWidth,
  needBorder = false,
}: ComboboxProps) => {
  const { open, toggleOpen, handleClose, onClickAway } = useOpenSelector();

  // Проверяем, существует ли значение в опциях
  const valueExistsInOptions = options.some((option: SelectItemInterface) => option.value === value?.value);

  // Если значение не существует, добавляем его в опции с пометкой удалено
  const modifiedOptions: SelectItemInterface[] | any = useMemo(() => {
    if (value && !valueExistsInOptions) {
      return [
        ...options,
        {
          ...value,
          value: `${value.value} (удалено)`,
          name: `${value.name} (удалено)`,
          isDeleted: true,
        },
      ];
    }
    return options;
  }, [options, value, valueExistsInOptions]);

  const getOptionLabel: (option: SelectItemInterface) => string = ({ value }) => value;

  const onComboboxChange: AutocompleteProps<SelectItemInterface, boolean, undefined, undefined>['onChange'] = (_, value) => {
    onChange(value);
  };

  const getPopperStyle = useCallback(
    (props: PopperProps) => ({
      minWidth,
      width: (props.anchorEl as Element)?.clientWidth || minWidth,
    }),
    [minWidth],
  );

  const PopperComponent = useCallback(
    (props: PopperProps) => <Popper {...props} style={getPopperStyle(props)} />,
    [getPopperStyle],
  );

  const valueSelect = value?.isDeleted ? { ...value, value: `${value.value} (удален)` } : value;

  return (
    <Container $width={width}>
      {title && <h5>{title}</h5>}
      <ClickAwayListener onClickAway={onClickAway}>
        <StyledCombobox
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: `var(${ColorVarsEnum.Level_1})`,
            },
          }}
          disabled={disabled}
          multiple={multiple}
          disableClearable={disableClearable}
          onChange={onComboboxChange}
          value={valueSelect === '' ? undefined : valueSelect}
          open={open}
          onOpen={toggleOpen}
          onClose={handleClose}
          noOptionsText="Не найдено"
          id={name}
          className="combobox"
          options={modifiedOptions}
          onKeyDown={(e) => e.stopPropagation()}
          renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
          renderOption={(props, option) => (
            <Tooltip disableHoverListener={tooltipDisableHoverListener} title={option.tooltip || option.name} placement="left">
              <MenuItem
                {...props}
                style={option.isDeleted ? { color: `var(${ColorVarsEnum.Alert})` } : {}}
                disabled={option.disabled}
              >
                <div>{option.name}</div>
              </MenuItem>
            </Tooltip>
          )}
          componentsProps={{
            popper: { className: SELECTOR_MENU, placement: 'bottom-start' },
          }}
          PopperComponent={PopperComponent}
          getOptionLabel={getOptionLabel}
          popupIcon={<DownIcon />}
          $heightSize={heightSize}
          $width={width}
          $needBackground={needBackground}
          $needBorder={needBorder}
          $visibleElements={visibleElements}
        />
      </ClickAwayListener>
    </Container>
  );
};
