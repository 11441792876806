import { useEffect, useRef } from 'react';

export const useClickOutside = (callback: () => void) => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideItems = itemRefs.current.every((ref) => !ref || !ref.contains(event.target as Node));

      if (isClickOutsideItems) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  const addItemRef = (el: HTMLDivElement) => {
    if (el && !itemRefs.current.includes(el)) {
      itemRefs.current.push(el);
    }
  };

  return addItemRef;
};
