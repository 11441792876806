import { NoopValueType } from 'types/global';
import { IdInterface } from 'types/store';
import { FlexDirectionEnum } from 'store/reducers/groupsVisualisations/types';
import { ArrowToDownIcon } from 'assets/icons/positionStyle';

export enum GroupContainerStylesEnum {
  ALIGN_TOP = 'start',
  ALIGN_CENTER = 'center',
  ALIGN_BOTTOM = 'end',
  JUSTIFY_START = 'start',
  JUSTIFY_END = 'end',
  JUSTIFY_CENTER = 'center',
  JUSTIFY_BETWEEN = 'space-between',
  JUSTIFY_AROUND = 'space-around',
}

export enum GroupAlignmentEnum {
  ALIGN = 'align',
  JUSTIFY = 'justify',
}

export type OptionsViewSettingsType = OptionViewSettingsInterface[];

export interface OptionViewSettingsInterface {
  title: string;
  id: string;
  Icon: typeof ArrowToDownIcon;
  onChange: NoopValueType<IdInterface>;
  colorVar: GroupContainerStylesEnum | FlexDirectionEnum;
}
