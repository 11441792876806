import { Option } from 'components/shared/CustomSelect/types';
import { setMonth, setYear } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { DateOptionsInput, DateRange } from './types';

export const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const byTypeOptions = (isRealData: boolean, maxData: string): Option[] => {
  const options: Option[] = [
    { value: 'byDay', title: 'Дни' },
    { value: 'byWeek', title: 'Недели' },
    { value: 'byMonth', title: 'Месяцы' },
    { value: 'byQuarter', title: 'Кварталы' },
    { value: 'byYear', title: 'Год' },
    { value: 'byToday', title: 'Сегодня' },
  ];

  if (isRealData) {
    const currentDate = new Date();
    const maxDate = new Date(maxData);
    const isTodayDisabled = currentDate > maxDate;

    return options.map((option) => ({
      ...option,
      disabled: option.value === 'byToday' && isTodayDisabled,
    }));
  }

  return options;
};

export const fontSizeOfDayBlock = '12px';

export const calculateDateRange = (data: DateOptionsInput): DateRange => {
  if (isEmpty(data.years) || isEmpty(data.months)) {
    // Устанавливаем даты на текущий момент, если массивы не итерируемы или пусты
    const currentDate = new Date();
    return { minDate: currentDate, maxDate: currentDate };
  }

  const sortedYears = [...data.years].sort((a, b) => a - b);
  const sortedMonths = [...data.months].sort((a, b) => a - b);

  const minYear = sortedYears[0];
  const maxYear = sortedYears[sortedYears.length - 1];
  const minMonth = sortedMonths[0] - 1; // Адаптация к индексу месяца JavaScript (0-11)
  const maxMonth = sortedMonths[sortedMonths.length - 1] - 1;

  const minDate = setMonth(setYear(new Date(), minYear), minMonth);
  const maxDate = setMonth(setYear(new Date(), maxYear), maxMonth);

  return { minDate, maxDate };
};

export const positionCenterOffset = -145;
export const positionFlexEndOffset = -283;
export const defaultPositionOffset = -8;
