import * as yup from 'yup';

import { ColorSchemeType } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/AIColorGenerationSettings/types';
import { SelectItemInterface } from 'modules/ui/Select';

export const colorGapAi = 16;
export const colorSizeAi = 30;
export const maxColorInGroupAi = 11;

export const colorSchemeItems: Array<SelectItemInterface<ColorSchemeType>> = [
  { name: 'Монохромные', value: 'monochrome', tooltip: 'Набор разных оттенков выбранных цветов' },
  { name: 'Комплементарные', value: 'complementary', tooltip: 'Набор разных оттенков контрастных цветов к выбранным' },
  { name: 'Умная генерация', value: 'ML', tooltip: 'Набор сочетающихся цветов к выбранным от модели ИИ' },
];

export const validationSchema = yup.object({
  numberVariations: yup
    .number()
    .required('Это поле обязательно')
    .min(1, 'Минимальное значение 1')
    .max(8, 'Максимальное значение 8')
    .typeError('Введите корректное число')
    .test('is-integer', 'Введите целое число', (value) => Number.isInteger(value)),
  colorScheme: yup
    .mixed<ColorSchemeType>()
    .oneOf(['monochrome', 'ML', 'complementary'], 'Неверная цветовая схема')
    .required('Выберите цветовую схему'),
  colors: yup
    .array()
    .of(
      yup
        .object({
          id: yup.string().required(),
          hex: yup.string().required('Цвет необходим'),
          h: yup.number().required(),
          s: yup.number().required(),
          l: yup.number().required(),
          opacity: yup.number().optional(),
          name: yup.mixed().nullable().optional(),
        })
        .required(),
    )
    .default([])
    .min(1, 'Для генерации необходимо добавить хотя бы один цвет'),
});

export const defaultNameGenerationColor = 'Результат генерации ';
