import { ThunkMiddleware } from 'redux-thunk/src/types';
import { updateLicenseStatus } from 'store/reducers/auth/actions';

//TODO FSD
export const licenseMiddleware: ThunkMiddleware = (storeAPI) => (next) => (action) => {
  if (action.type.endsWith('/rejected') && action.error?.message) {
    if (action.payload === 'У лицензии истек период пользования') {
      storeAPI.dispatch(updateLicenseStatus(false));
    }
  }

  return next(action);
};
