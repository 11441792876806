import { AccessLevel } from 'types/store';
import React, { LazyExoticComponent } from 'react';

export type LoadRoute = 'model' | 'console';

export type AdminRoute =
  | 'adminUser'
  | 'adminTasks'
  | 'adminActiveDirectories'
  | 'adminLicenses'
  | 'adminFlows'
  | 'adminUsers'
  | 'adminGroups'
  | 'adminSources'
  | 'adminSettings'
  | 'activationKey';

export type Route =
  | 'preview'
  | 'board'
  | 'console'
  | 'login'
  | 'projects'
  | 'projectsFlowsId'
  | 'projectsFlows'
  | 'projectsFlowsDrafts'
  | 'list'
  | 'access'
  | LoadRoute;

export type ProjectIdParam = 'projectId';
export type PageIdParam = 'pageId';

export interface RouteConfig {
  path: string;
  access: AccessLevel;
  title: string;
  component: LazyExoticComponent<React.FC>;
  isProjectRoute?: boolean;
}

export enum PAGES {
  LOGIN = 'login',
  ACTIVATION_KEY = 'activationKey',
  MODELS = ':projectId/model',
  CONSOLE = ':projectId/console',
  WORKSPACE = ':projectId/board/:pageId',
  ADMIN_LICENSES = 'adminLicenses',
  ADMIN_GROUPS = 'adminGroups',
  ADMIN_FLOWS = 'adminFlows',
  ADMIN_TASKS = 'adminTasks',
  ADMIN_ACTIVE_DIRECTORIES = 'adminActiveDirectories',
  ADMIN_SOURCES = 'adminSources',
  ADMIN_SETTINGS = 'adminSettings',
  ADMIN_USERS = 'adminUsers',
  PROJECTS_FLOWS = 'projects/flows/:id/list',
  PROJECTS_ACCESS = 'projects/flows/:id/access',
  PROJECTS_DRAFTS = 'projects/flows/drafts',
}
