import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { SetupButtonContainer } from 'modules/settingsContainer/SettingsFieldEntry/styles';

export interface SettingsFieldWrapperProps {
  isActive?: boolean;
  isSelected?: boolean;
  disabledViewSetup?: boolean;
  disabledBorderContainer?: boolean;
  paddingSettingsContainer?: string;
}

export const SettingsFieldWrapper = styled.div<SettingsFieldWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: ${({ paddingSettingsContainer }) => paddingSettingsContainer || '8px;'};
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 150ms;

  ${({ disabledBorderContainer }) =>
    !disabledBorderContainer &&
    css`
      border: 1px solid var(${ColorVarsEnum.Level_3_menu});

      :hover {
        border: 1px solid var(${ColorVarsEnum.Level_5});
      }
    `}

  :hover {
    ${SetupButtonContainer} {
      ${({ disabledViewSetup }) => !disabledViewSetup && 'display: flex;'}
    }
  }

  ${({ isSelected, disabledBorderContainer }) =>
    isSelected &&
    !disabledBorderContainer &&
    css`
      border: 1px solid var(${ColorVarsEnum.Level_5});
    `}

  ${({ isActive, disabledBorderContainer }) =>
    isActive &&
    !disabledBorderContainer &&
    css`
      border: 1px solid var(${ColorVarsEnum.Accent});
      :hover {
        border: 1px solid var(${ColorVarsEnum.Accent});
      }
    `}
`;
