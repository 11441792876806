import styled from 'styled-components';
import { settingsLayoutMixin } from 'modules/settingsContainer/SettingsLayout/styles';
import { defaultScrollbarMixin } from 'constants/styles';

type FieldSettingsAreaLayoutProps = {
  right?: string;
  top?: string;
};

export const FieldSettingsAreaLayout = styled.div<FieldSettingsAreaLayoutProps>`
  position: fixed;
  top: ${({ top }) => top || '0'};
  right: ${({ right }) => right || '0'};
  z-index: 4;
`;

export interface HeaderSettingsWrapperProps {
  width?: string;
  minWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  overflow?: string;
  boxShadow?: string;
  backgroundColor?: string;
}

export const HeaderSettingsWrapper = styled.div<HeaderSettingsWrapperProps>`
  ${settingsLayoutMixin};
  width: unset;
  ${defaultScrollbarMixin};
  ${({ overflow }) => `overflow: ${overflow ? overflow : 'auto'}`};
  ${({ width }) => width && `width: ${width};`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
  ${({ height }) => height && `height: ${height};`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`};
`;
