import styled, { css } from 'styled-components';
import { settingsTabHeight } from 'modules/ui/tabs/SettingsTabs/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { bottomLineMixin, defaultScrollbarMixin } from 'constants/styles';
import { ContainerChildren } from 'modules/settingsContainer/SettingsLayout/styles';

export const SettingsTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(${ColorVarsEnum.Level_3_menu});
`;

export const TabsWrapper = styled(ContainerChildren)`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  height: ${settingsTabHeight};

  :after {
    ${bottomLineMixin}
  }
`;

interface TabItemProps {
  isActive: boolean;
}

export const TabItem = styled.div<TabItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 15px;
  cursor: pointer;

  span {
    line-height: 100%;
    color: var(${ColorVarsEnum.Level_1});
  }

  ${({ isActive }) =>
    isActive &&
    css`
      span {
        color: var(${ColorVarsEnum.Accent});
      }

      :after {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: var(${ColorVarsEnum.Accent});
        height: 3px;
        width: 100%;
        z-index: 1;
      }
    `}
`;

export const BodyWrapper = styled(ContainerChildren)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 83px);
  overflow-y: auto;
  position: relative;

  ${defaultScrollbarMixin};
`;
