import { IsLoadingInterface, ReactFCNoop } from 'types/global';
import { IconType } from 'assets/icons/withContainer';

export type Tab = {
  id: string;
  Component: ReactFCNoop;
} & (
  | { type: 'text'; content: string; prompt: string }
  | { type: 'icon'; content: IconType; prompt?: string }
  | { type: 'component'; content: ReactFCNoop; prompt?: string }
) &
  Partial<IsLoadingInterface>;

export enum TabTypeEnum {
  TEXT = 'text',
  ICON = 'icon',
  COMPONENT = 'component',
}
