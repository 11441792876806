import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import { defaultVisualisationWithActiveIncisionId } from 'modules/workspace/components/WorkAreaSpace/constants';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import { setActiveBoardElement } from 'store/reducers/board';
import { addToLayerAction, deleteFromLayerByIdAction } from 'store/reducers/board/actions';
import { getActiveBoardElements } from 'store/reducers/board/getters';
import {
  loadEnabledFiltersByPageId,
  loadFiltersByPageId,
  loadFiltersByProjectId,
  loadGlobalEnabledFiltersByPageId,
} from 'store/reducers/filters/api';
import { initialFilterStoreState, referenceEnabledFilter, referenceFilters } from 'store/reducers/filters/constants';
import {
  getEnabledFilterByFilterId,
  getEnabledFiltersAlreadyLoaded,
  getEnabledFiltersOnPage,
  getFiltersAlreadyLoaded,
  getFiltersByPageId,
  getProjectFilterById,
} from 'store/reducers/filters/getters';
import {
  DateEnabledFilterInterface,
  DateFilterInterface,
  EnabledFilterDataType,
  EnabledFilterIdsByPageInterface,
  EnableFilterActionPayload,
  FilterActionsTypes,
  FilterDataType,
  FilterIdsByPageInterface,
  FilterMapInterface,
  FiltersValuesType,
  FilterType,
  LoadEnabledFiltersOutput,
  LoadFieldsPayload,
  LoadFieldsResponse,
  LoadFieldsResponseInterface,
  LoadFiltersOutput,
  LoadGlobalEnabledFiltersOutput,
  MultipleEnabledFilterInterface,
  MultipleFilterInterface,
  SingleEnabledFilterInterface,
  SingleFilterInterface,
  UpdateEnabledFilterPayload,
  UpdateFilterPayload,
} from 'store/reducers/filters/types';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';
import { updateDataSettingsById, updateHeatmapDataSettingsById } from 'store/reducers/visualisations/actions';
import { loadDataBySql } from 'store/reducers/visualisations/api';
import { getVisualisationById, getVisualisations } from 'store/reducers/visualisations/getters';
import {
  BackgroundSettingsInterface,
  HeatmapDataSettings,
  LimitSettingInterface,
  VisualisationTypeEnum,
} from 'store/reducers/visualisations/types';
import { PageIdInterface, ProjectIdInterface, ProjectIdWithType, SqlDataRequestInterface } from 'types/store';
import { v4 } from 'uuid';
import {
  addNewFilter,
  addNewFilterByData,
  disableFilterById,
  enableFilter,
  removeFilterById,
  removeFilterValuesById,
  removeFromFilterLoadingListById,
  setSlice,
  updateEnabledFilter,
  updateFilter,
} from '.';
import isNull from 'lodash/isNull';
import { getAstOfVisualisationById } from '../ast/getters';
import {
  generateFilterSqlString,
  generateWidgetName,
  getSqlStringIncisionsForEnabledFilter,
  mapValuesToColors,
  mergeByReference,
} from 'utils/utils';

/* TODO: Adding types and interface for Error Messages  */
const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadFiltersAction = createAsyncThunk(
  FilterActionsTypes.LOAD_FILTERS,
  async (params: ProjectIdWithType<PageIdInterface>, { signal, getState, dispatch }) => {
    const filtersAlreadyLoaded = getFiltersAlreadyLoaded(params.pageId)(getState() as TState);

    if (!filtersAlreadyLoaded) {
      const request = dispatch(loadFiltersByPageIdAction(params));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadEnabledFiltersAction = createAsyncThunk(
  FilterActionsTypes.LOAD_ENABLED_FILTERS,
  async (params: ProjectIdWithType<PageIdInterface>, { signal, getState, dispatch }) => {
    const enabledFiltersAlreadyLoaded = getEnabledFiltersAlreadyLoaded(params.pageId)(getState() as TState);

    if (!enabledFiltersAlreadyLoaded) {
      const request = dispatch(loadEnabledFiltersByPageIdAction(params));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadFiltersByPageIdAction = createAsyncThunk<LoadFiltersOutput, ProjectIdWithType<PageIdInterface>>(
  FilterActionsTypes.LOAD_FILTERS_BY_PAGE_ID,
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await loadFiltersByPageId(params, { signal }),
        originFilters = response.data.filters;

      /* Merging reference filter data with filters data stored on the BE. When new data (new features) is added to the filter, this data will be synchronized with the BE data. */
      const filters = mergeByReference({
        originData: originFilters,
        getReferenceByType: (type) => referenceFilters[type as FilterType],
        typeKey: 'type',
      });

      const filtersByPages = { [params.pageId]: new Set(originFilters.map(({ id }) => id)) };

      return { filters, filtersByPages };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ filters: {}, filtersByPages: {} });
    }
  },
);

export const loadFiltersByProjectIdAction = createAsyncThunk<FilterDataType[], ProjectIdInterface>(
  FilterActionsTypes.LOAD_FILTERS_BY_PROJECT_ID,
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await loadFiltersByProjectId(params, { signal });

      return response.data.filters;
    } catch (err: any) {
      validateError(err, rejectWithValue);
    }
  },
);

export const loadFiltersFromSnapshotAction = createAsyncThunk<LoadFiltersOutput, SettingsSnapshotType['filters']>(
  FilterActionsTypes.LOAD_FILTERS_FROM_SNAPSHOT,
  (filters) => {
    const originFilters = filters.reduce<FilterDataType[]>(
      (result, data) => [...result, ...data.filters],
      [] as FilterDataType[],
    );

    const mergedFilters = mergeByReference({
      originData: originFilters,
      getReferenceByType: (type) => referenceFilters[type as FilterType],
      typeKey: 'type',
    });

    const filtersByPages = filters.reduce<FilterIdsByPageInterface>(
      (result, { pageId, filters }) => ({ ...result, [pageId]: new Set<string>(filters.map(({ id }) => id)) }),
      {},
    );

    const filtersByProject = filters.reduce<FilterMapInterface>((acc, item) => {
      item.filters.forEach((filter) => {
        acc[filter.id] = filter;
      });
      return acc;
    }, {});

    return { filters: mergedFilters, filtersByPages, filtersByProject };
  },
);

export const loadEnabledFiltersByPageIdAction = createAsyncThunk<LoadEnabledFiltersOutput, ProjectIdWithType<PageIdInterface>>(
  FilterActionsTypes.LOAD_ENABLED_FILTER_BY_PAGE_ID,
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await loadEnabledFiltersByPageId(params, { signal }),
        originEnabledFilters = response.data.enabledFilters.filter((filter) => !filter.isGlobal);

      /* Merging reference filter data with filters data stored on the BE. When new data (new features) is added to the filter, this data will be synchronized with the BE data. */
      const enabledFilters = mergeByReference({
        originData: originEnabledFilters,
        getReferenceByType: (type) => referenceEnabledFilter[type as FilterType],
        typeKey: 'type',
      });

      const enabledFiltersByPages = { [params.pageId]: new Set(originEnabledFilters.map(({ id }) => id)) };

      return { enabledFilters, enabledFiltersByPages };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ enabledFilters: {}, enabledFiltersByPages: {} });
    }
  },
);

export const loadGlobalEnabledFiltersAction = createAsyncThunk<LoadGlobalEnabledFiltersOutput, string>(
  FilterActionsTypes.LOAD_GLOBAL_ENABLED_FILTER_BY_PAGE_ID,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadGlobalEnabledFiltersByPageId(projectId),
        originGlobalEnabledFilters = response.data.globalEnabledFilters;

      const globalEnabledFilters = mergeByReference({
        originData: originGlobalEnabledFilters,
        getReferenceByType: (type) => referenceEnabledFilter[type as FilterType],
        typeKey: 'type',
      });

      const enabledFiltersByPages = originGlobalEnabledFilters.reduce<EnabledFilterIdsByPageInterface>(
        (result, { pageId, id }) => {
          const oldEnabledFiltersByPages = result[pageId] || new Set<string>();
          oldEnabledFiltersByPages.add(id);

          return { ...result, [pageId]: oldEnabledFiltersByPages };
        },
        {},
      );

      return { enabledFilters: globalEnabledFilters, enabledFiltersByPages };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ enabledFilters: {}, enabledFiltersByPages: {} });
    }
  },
);

export const loadEnabledFiltersFromSnapshotAction = createAsyncThunk<
  LoadEnabledFiltersOutput,
  SettingsSnapshotType['enabledFilters']
>(FilterActionsTypes.LOAD_ENABLED_FILTER_FROM_SNAPSHOT, (enabledFilters) => {
  const originEnabledFilters = enabledFilters.reduce<EnabledFilterDataType[]>(
    (result, data) => [...result, ...data.enabledFilters],
    [] as EnabledFilterDataType[],
  );

  const mergedEnabledFilters = mergeByReference({
    originData: originEnabledFilters,
    getReferenceByType: (type) => referenceEnabledFilter[type as FilterType],
    typeKey: 'type',
  });

  const enabledFiltersByPages = enabledFilters.reduce<EnabledFilterIdsByPageInterface>(
    (result, { pageId, enabledFilters }) => ({ ...result, [pageId]: new Set<string>(enabledFilters.map(({ id }) => id)) }),
    {},
  );

  return { enabledFilters: mergedEnabledFilters, enabledFiltersByPages };
});

export const clearFiltersStore = createAsyncThunk(FilterActionsTypes.CLEAR_FILTERS_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialFilterStoreState));
});

export const addFilterAction = createAsyncThunk(FilterActionsTypes.ADD_FILTER, (type: FilterType, { getState, dispatch }) => {
  const pageId = getActivePageId(getState() as TState) || '';
  const filters = getFiltersByPageId(pageId)(getState() as TState);
  const name = generateWidgetName(filters, type);
  const id = v4();

  dispatch(addNewFilter({ type, pageId, id, name }));
  dispatch(addToLayerAction(id));
  dispatch(setActiveBoardElement({ id }));
});

export const addFilterByDataAction = createAsyncThunk(FilterActionsTypes.ADD_FILTER, (data: FilterDataType, { dispatch }) => {
  dispatch(addNewFilterByData(data));
});

export const removeFilterValuesAction = createAsyncThunk<void, string>(
  FilterActionsTypes.REMOVE_FILTER_VALUES,
  (id, { dispatch }) => {
    dispatch(removeFilterValuesById(id));
  },
);

export const removeFromFilterLoadingListAction = createAsyncThunk<void, string>(
  FilterActionsTypes.REMOVE_FROM_FILTER_LOADING_LIST,
  (id, { dispatch }) => {
    dispatch(removeFromFilterLoadingListById(id));
  },
);

export const removeFilterByIdAction = createAsyncThunk<void, string>(
  FilterActionsTypes.REMOVE_FILTER_ID,
  (id: string, { dispatch, getState }) => {
    dispatch(removeFilterById({ id }));
    dispatch(removeFilterValuesAction(id));
    dispatch(removeFromFilterLoadingListAction(id));
    dispatch(deleteFromLayerByIdAction(id));

    const enabledFilter = getEnabledFilterByFilterId(id)(getState() as TState);
    if (enabledFilter) {
      dispatch(disableFilterByIdAction(enabledFilter.id));
    }
  },
);

export const updateTypedFilterActionById = <FilterInterface extends FilterDataType = FilterDataType>(
  params: UpdateFilterPayload<FilterInterface>,
) =>
  createAsyncThunk<void, UpdateFilterPayload<FilterInterface>>(FilterActionsTypes.UPDATE_FILTER, ({ id, data }, { dispatch }) => {
    dispatch(updateFilter({ id, data }));
  })(params);

export const updateFilterActionById = updateTypedFilterActionById;
export const updateSingleFilterActionById = updateTypedFilterActionById<SingleFilterInterface>;
export const updateMultipleFilterActionById = updateTypedFilterActionById<MultipleFilterInterface>;
export const updateDateFilterActionById = updateTypedFilterActionById<DateFilterInterface>;

export const updateTypedFilterAction = <FilterInterface extends FilterDataType = FilterDataType>(
  params: Partial<UpdateFilterPayload<FilterInterface>['data']>,
) =>
  createAsyncThunk<void, Partial<UpdateFilterPayload<FilterInterface>['data']>>(
    FilterActionsTypes.UPDATE_FILTER,
    (data, { dispatch, getState }) => {
      const activeIds = getActiveBoardElements(getState() as TState);

      activeIds && dispatch(updateTypedFilterActionById<FilterInterface>({ id: activeIds[0], data }));
    },
  )(params);

export const updateFilterAction = updateTypedFilterAction;
export const updateSingleFilterAction = updateTypedFilterAction<SingleFilterInterface>;

export const typedEnableFilterAction = <EnabledFilterInterface extends EnabledFilterDataType>(
  params: EnableFilterActionPayload<EnabledFilterInterface & { isTableFilter?: boolean }>,
  defaultPageId?: string,
) =>
  createAsyncThunk<void, EnableFilterActionPayload<EnabledFilterInterface>>(
    FilterActionsTypes.ENABLE_FILTER,
    (data, { dispatch, getState }) => {
      const { incisionRequest: incisionSqlRequest } = data;
      const { isTableFilter, linkId } = params;
      const pageId = defaultPageId ? defaultPageId : getActivePageId(getState() as TState) || '';
      const id = v4();
      const visualisations = getVisualisations(getState() as TState);
      const incisionData = getAstOfVisualisationById(linkId)(getState() as TState);
      const incisionRequest = isTableFilter ? incisionSqlRequest : getSqlStringIncisionsForEnabledFilter(incisionData);
      const filters = getEnabledFiltersOnPage(pageId)(getState() as TState)
        .filter((filter) => filter.linkId === data.linkId)
        .map((filter) => filter.nameSettings.fieldName);

      filters.push(data.nameSettings.fieldName);

      Object.keys(visualisations).forEach((visualisationKey) => {
        const visualisation = visualisations[visualisationKey];

        if (visualisation?.pageId === pageId && visualisation?.events.filterSettings.responseToExternalDrillDown) {
          const { activeIncisionId, incisions } = visualisation.dataSettings;
          const activeIncision = incisions.find((incision) => incision.id === activeIncisionId);
          let nextActiveIncisionId: string | null = null;

          for (const incision of incisions) {
            if (!filters.includes(incision.fieldName)) {
              nextActiveIncisionId = incision.id;
              break;
            }
          }

          if (nextActiveIncisionId && activeIncision?.fieldName === data.nameSettings.fieldName) {
            dispatch(
              updateDataSettingsById({
                dataSettings: { activeIncisionId: nextActiveIncisionId, isDrillDown: true },
                id: visualisation.id,
              }),
            );
          }
        }
      });

      dispatch(enableFilter({ id, pageId, incisionRequest, ...data } as EnabledFilterDataType));
    },
  )(params);

export const enableActivateObjectFilters = createAsyncThunk<void, string>(
  FilterActionsTypes.ENABLE_FILTER,
  async (id, { dispatch, getState }) => {
    const state = getState() as TState;
    const visualisation = getVisualisationById(id)(state);

    if (visualisation) {
      const { events } = visualisation,
        { activateObject } = events,
        { filters, isActive } = activateObject;

      if (!filters || !isActive) return;

      filters.forEach(({ id, selectedValues, isActive }) => {
        if (!isActive) return;

        const filterData = getProjectFilterById(id)(state);
        const alreadyEnabledFilter = getEnabledFilterByFilterId(id)(state);

        if (filterData && selectedValues) {
          const {
            isGlobal,
            filterInfluences,
            isRealData,
            nameSettings,
            type,
            pageId,
            sqlData: { incisionRequest },
          } = filterData;

          const params: EnableFilterActionPayload = {
            filterInfluences: filterInfluences,
            isGlobal: isGlobal,
            isRealData: isRealData,
            link: 'filter',
            linkId: id,
            modelId: filterData.modelId,
            incisionRequest,
            nameSettings: {
              name: nameSettings.name,
              fieldName: nameSettings.fieldName,
              nameFromDatabase: nameSettings.nameFromDatabase,
              filterElementMode: nameSettings.filterElementMode,
            },
            selectedValues,
            type: type,
          };

          if (alreadyEnabledFilter) {
            return dispatch(
              updateEnabledFilterAction({ id: alreadyEnabledFilter?.id, data: { ...alreadyEnabledFilter, selectedValues } }),
            );
          }

          dispatch(enableFilterAction(params, pageId));
        }
      });
    }
  },
);

export const enableFilterAction = typedEnableFilterAction;
export const enableSingleFilterAction = typedEnableFilterAction<SingleEnabledFilterInterface>;
export const enableMultipleFilterAction = typedEnableFilterAction<MultipleEnabledFilterInterface>;
export const enableDateFilterAction = typedEnableFilterAction<DateEnabledFilterInterface>;

export const typedUpdateEnabledFilterAction = <EnabledFilterInterface extends EnabledFilterDataType>(
  params: UpdateEnabledFilterPayload<EnabledFilterInterface>,
) =>
  createAsyncThunk<void, UpdateEnabledFilterPayload<EnabledFilterInterface>>(
    FilterActionsTypes.UPDATE_ENABLED_FILTER,
    (params, { dispatch }) => {
      dispatch(updateEnabledFilter(params));
    },
  )(params);

export const updateEnabledFilterAction = typedUpdateEnabledFilterAction;
export const enableEnabledSingleFilterAction = typedUpdateEnabledFilterAction<SingleEnabledFilterInterface>;
export const enableEnabledMultipleFilterAction = typedUpdateEnabledFilterAction<MultipleEnabledFilterInterface>;
export const enableEnabledDateFilterAction = typedUpdateEnabledFilterAction<DateEnabledFilterInterface>;

export const disableFilterByIdAction = createAsyncThunk<unknown, string>(
  FilterActionsTypes.DISABLE_FILTER,
  (id, { dispatch }) => {
    dispatch(disableDrillDownWithFilterByIdAction(id));
    dispatch(disableFilterById(id));
  },
);

export const disableDrillDownWithFilterByIdAction = createAsyncThunk<unknown, string>(
  FilterActionsTypes.CLIMBING_INCISION,
  (id, { dispatch, getState }) => {
    const activePadeId = getActivePageId(getState() as TState);
    const enabledFilters = getEnabledFiltersOnPage(activePadeId)(getState() as TState);
    const deletedFilter = enabledFilters.find((filter) => filter.id === id);
    const visualisationId = deletedFilter?.linkId;
    const visualisation = visualisationId && getVisualisationById(visualisationId)(getState() as TState);
    const visualisations = getVisualisations(getState() as TState);
    const activeIncisionId =
      visualisation &&
      visualisation.dataSettings.incisions.find((incision) => incision.fieldName === deletedFilter.nameSettings.fieldName)?.id;
    const allListFiltersForVisualisation = enabledFilters.filter((filter) => filter.linkId === visualisationId);
    const isDefaultVisualisation = visualisation
      ? defaultVisualisationWithActiveIncisionId.includes(visualisation.visualisationType)
      : false;
    const isHeatmapVisualisation = visualisation && visualisation.visualisationType === VisualisationTypeEnum.HEATMAP;
    const lastVisualisationFilter = allListFiltersForVisualisation[allListFiltersForVisualisation.length - 1];
    const isExtremeFilter = lastVisualisationFilter.id === deletedFilter?.id;

    if (deletedFilter) {
      Object.keys(visualisations).forEach((visualisationKey) => {
        const visualisation = visualisations[visualisationKey];

        if (
          visualisation?.pageId === activePadeId &&
          defaultVisualisationWithActiveIncisionId.includes(visualisation.visualisationType) &&
          visualisation?.events.filterSettings.responseToExternalDrillDown
        ) {
          const { activeIncisionId, incisions } = visualisation.dataSettings;
          const activeIncisionIndex = incisions.findIndex((incision) => incision.id === activeIncisionId);
          const nextActiveIncision = incisions[activeIncisionIndex - 1];

          if (allListFiltersForVisualisation.length === 1) {
            dispatch(
              updateDataSettingsById({
                dataSettings: { activeIncisionId: incisions[0].id, isDrillDown: false },
                id: visualisation.id,
              }),
            );
          } else if (nextActiveIncision.fieldName === deletedFilter.nameSettings.fieldName) {
            dispatch(
              updateDataSettingsById({
                dataSettings: { activeIncisionId: nextActiveIncision.id },
                id: visualisation.id,
              }),
            );
          }
        }
      });
    }

    if (allListFiltersForVisualisation.length === 1 && visualisationId && visualisation) {
      if (isDefaultVisualisation) {
        const firstIncisionId = visualisation.dataSettings.incisions?.[0].id;
        return dispatch(
          updateDataSettingsById({
            dataSettings: { activeIncisionId: firstIncisionId, isDrillDown: false },
            id: visualisationId,
          }),
        );
      }

      if (isHeatmapVisualisation) {
        const heatmapDataSettings = visualisation.dataSettings as HeatmapDataSettings;
        const activeVerticalIncisionId = heatmapDataSettings.verticalIncisions[0].id;
        const activeHorizontalIncisionId = heatmapDataSettings.incisions?.[0].id;

        return (
          activeVerticalIncisionId &&
          activeHorizontalIncisionId &&
          dispatch(
            updateHeatmapDataSettingsById({
              dataSettings: { activeVerticalIncisionId, activeHorizontalIncisionId, isDrillDown: false },
              id: visualisationId,
            }),
          )
        );
      }
    }
    if (isExtremeFilter && activeIncisionId && visualisationId) {
      if (isDefaultVisualisation) {
        return dispatch(
          updateDataSettingsById({
            dataSettings: { activeIncisionId },
            id: visualisationId,
          }),
        );
      }

      if (isHeatmapVisualisation) {
        if (activeIncisionId) {
          return dispatch(
            updateHeatmapDataSettingsById({
              dataSettings: { activeHorizontalIncisionId: activeIncisionId },
              id: visualisationId,
            }),
          );
        } else {
          const heatmapDataSettings = visualisation.dataSettings as HeatmapDataSettings;
          const activeVerticalIncisionId = heatmapDataSettings.verticalIncisions.find(
            (incision) => incision.fieldName === deletedFilter.nameSettings.fieldName,
          )?.id;
          return (
            activeVerticalIncisionId &&
            dispatch(
              updateHeatmapDataSettingsById({
                dataSettings: { activeVerticalIncisionId },
                id: visualisationId,
              }),
            )
          );
        }
      }
    }
  },
);

export const loadFieldsAction = createAsyncThunk<
  LoadFieldsResponse,
  LoadFieldsPayload & {
    isDataFilter?: boolean;
    sqlData: SqlDataRequestInterface;
    limit?: LimitSettingInterface;
    sortingStatus?: string | null;
    backgroundSettings?: BackgroundSettingsInterface;
  },
  {
    rejectValue: string;
  }
>(
  FilterActionsTypes.LOAD_FILTER_VALUES,
  async (
    {
      projectId,
      fieldName,
      isDataFilter = false,
      fromQuery,
      whereQuery,
      modelId,
      searchString,
      sqlData,
      limit,
      sortingStatus,
      backgroundSettings,
    },
    { rejectWithValue, signal },
  ) => {
    try {
      const finalSql = generateFilterSqlString({
        sqlData,
        whereQuery,
        searchString,
        limit,
        sortingStatus,
        fromQuery,
        fieldName,
        isDataFilter,
        backgroundSettings,
      });

      const response = await loadDataBySql<LoadFieldsResponseInterface>(
        {
          project: projectId,
          sql: finalSql,
          modelId,
          /*TODO: Comment on RLS and wait for the server */
          // rls,
        },
        { signal },
      );

      if (isNull(response.data?.value?.[0]) && isDataFilter) {
        throw new Error('Нет данных');
      }

      let filteredData: FiltersValuesType = [];

      if (response.data?.value && response.data?.count) {
        /* TODO: If on BE will converting value to string - delete code for converting to string on FE */
        filteredData = response.data?.value.reduce<FiltersValuesType>((result, value, valueIndex) => {
          if (value !== null) {
            return [...result, { value: String(value), count: response.data?.count?.[valueIndex] || 0 }];
          }

          return result;
        }, []);
      }

      if (response.data?.value && isDataFilter) {
        const value = response.data?.value.map((date) => String(date).replace(/"/g, ''));

        filteredData = value[0].split(', ').reduce<FiltersValuesType>((result, value, valueIndex) => {
          if (response.data?.value !== null) {
            return [...result, { value: String(value), count: response.data?.count?.[valueIndex] || 0 }];
          }

          return result;
        }, []);
      }

      if (isNull(response.data?.value?.[0]) && isDataFilter) {
        throw new Error('Нет данных');
      }

      const sqlLimit = limit?.isActive ? limit?.value : '2000';
      const finalLimit = Number(sqlLimit) + 1;
      const isOverLimit: boolean = response.data?.value?.length === finalLimit;

      if (isOverLimit) {
        filteredData.pop();
      }

      const conditionColorsObject = mapValuesToColors(response.data?.backgroundConditionValue || [], backgroundSettings);

      return { data: filteredData, conditionColorsObject, isOverLimit };
    } catch (err: any) {
      const errorMessage: string = err?.response?.data?.message || err;
      return rejectWithValue(errorMessage);
    }
  },
);
