import { VisualSettingsStateInterface } from 'store/reducers/visualSettings/types';
import { PanelModal } from 'modules/workspace/components/panelSettingsApp';

export const initialVisualSettingsStoreState: VisualSettingsStateInterface = {
  SettingComponent: PanelModal,
  openSettingsComponents: {
    PagesSettings: {
      isOpen: false,
      width: 340,
    },
    SettingsTab: {
      isOpen: false,
      width: 340,
    },
    SQLFullCode: {
      isOpen: false,
      width: 340,
    },
  },
  visibilityAllSettings: true,
};
