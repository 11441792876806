import { Button } from 'modules/ui/index';
import React from 'react';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import { IButton } from 'modules/ui/Button';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

interface TooltipIconButtonProps extends Pick<IButton, 'onClick' | 'leftIcon' | 'selected'>, Pick<TooltipProps, 'title'> {}

export const TooltipIconButton = ({ title, ...buttonProps }: TooltipIconButtonProps & IButton) => (
  <StyledTooltip title={title}>
    <Button heightSize={'small' || buttonProps.heightSize} needBackground={false} iconSize="big" {...buttonProps} />
  </StyledTooltip>
);
