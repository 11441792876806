import { AddAndDeleteButtons, AddAndDeleteButtonsProps } from 'modules/settingsContainer/AddAndDeleteButtons';
import { MetricWrapper } from 'modules/settingsContainer/common/data/MetricSettings/styles';
import {
  ControlsInterface,
  FieldSettingsRenderType,
  MetricsRenderType,
} from 'modules/settingsContainer/common/data/MetricSettings/types';
import { FieldSettingsArea } from 'modules/settingsContainer/FieldSettingsArea';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import React, { useCallback } from 'react';
import { VisualisationIdInterface } from 'store/reducers/visualisations/types';
import { FlexContainer } from 'styles/FlexContainer';
import useControls from 'utils/hooks/useControl';

interface IncisionSettingsProps<Metric extends VisualisationIdInterface>
  extends Pick<AddAndDeleteButtonsProps, 'onAdd' | 'addButtonText'> {
  titleText?: string;
  metrics: Metric[];
  metricRender: MetricsRenderType<Metric>;
  fieldSettingsRender?: FieldSettingsRenderType<Metric>;
  disableAddingMetric?: boolean;
  disableDeleteButton?: boolean;
  onDelete?: (id: string | null) => void;
  controls?: ControlsInterface;
  maxMetricListHeight?: string;
  disabledMainContainerSettings?: boolean;
  titleTooltip?: string;
}

export const MetricSettings = <Metric extends VisualisationIdInterface>({
  metrics,
  metricRender,
  fieldSettingsRender,
  onDelete,
  onAdd,
  disableAddingMetric,
  titleTooltip,
  titleText,
  addButtonText,
  controls,
  disableDeleteButton = metrics.length === 1,
  disabledMainContainerSettings,
  maxMetricListHeight,
}: IncisionSettingsProps<Metric>) => {
  const { activeMetricId, setActiveMetricId, selectedMetricId, setSelectedMetricId, onCloseFieldSettings, onUnselectMetric } =
    useControls({ controls });

  const activeMetric = metrics?.find(({ id }) => id === activeMetricId);
  const selectMetric = metrics?.find(({ id }) => id === selectedMetricId);

  const onLocalDelete = useCallback(() => {
    onUnselectMetric();
    onCloseFieldSettings();
    onDelete && onDelete(selectedMetricId);
  }, [onCloseFieldSettings, onDelete, onUnselectMetric, selectedMetricId]);

  const onDropDownClick = useCallback(() => {
    selectMetric && onUnselectMetric();
    activeMetric && onCloseFieldSettings();
  }, [activeMetric, onCloseFieldSettings, onUnselectMetric, selectMetric]);

  const Container = useCallback(
    () => (
      <>
        <FlexContainer flexDirection="column" gap="12px" width="100%">
          <MetricWrapper maxHeight={maxMetricListHeight}>
            {metricRender({
              metrics: metrics,
              activeMetricId,
              setActiveMetricId,
              selectedMetricId,
              setSelectedMetricId,
            })}
          </MetricWrapper>

          {!disableAddingMetric && (
            <AddAndDeleteButtons
              addButtonText={addButtonText}
              disable={{ deleteButton: !selectedMetricId || disableDeleteButton }}
              onDelete={onLocalDelete}
              onAdd={onAdd}
            />
          )}
        </FlexContainer>
      </>
    ),
    [
      activeMetricId,
      addButtonText,
      disableAddingMetric,
      disableDeleteButton,
      maxMetricListHeight,
      metricRender,
      metrics,
      onAdd,
      onLocalDelete,
      selectedMetricId,
      setActiveMetricId,
      setSelectedMetricId,
    ],
  );

  return (
    <>
      {!disabledMainContainerSettings && (
        <MainContainerSettings
          onDropDownClick={titleText ? onDropDownClick : undefined}
          titleText={titleText || ''}
          titleTooltip={titleTooltip}
          customPadding="12px 8px 12px"
          isOpenDefault={true}
        >
          <Container />
        </MainContainerSettings>
      )}

      {disabledMainContainerSettings && <Container />}

      {activeMetric && fieldSettingsRender && (
        <FieldSettingsArea onClose={onCloseFieldSettings}>
          {fieldSettingsRender({
            metric: activeMetric,
            metrics,
          })}
        </FieldSettingsArea>
      )}
    </>
  );
};
