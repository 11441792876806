import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import {
  deleteFontsAction,
  loadAdminFontsProjectSettingsAction,
  loadVersionStrategyAction,
} from 'store/reducers/adminSettings/actions';
import { UpdateCurrentFontPayload, VersionStrategy } from 'store/reducers/adminSettings/types';
import { FontFamilyDataSettingsInterface } from 'types/store';
import { initialAdminSettingsStoreState } from './constants';

export const adminSettingsSlice = createSlice({
  name: 'adminSettings',
  initialState: initialAdminSettingsStoreState,
  reducers: {
    setSlice: setSliceFn,
    setVersionStrategy: (state, { payload }: PayloadAction<VersionStrategy>) => {
      state.versionStrategy.versionStrategyData = payload;
    },
    updateFontSettings: (state, { payload: fontData }: PayloadAction<FontFamilyDataSettingsInterface>) => {
      state.fontSettings.fontSettingsData = fontData;
    },
    updateCurrentFontSettings: (state, { payload: { currentFont } }: PayloadAction<UpdateCurrentFontPayload>) => {
      state.fontsProjectSettings.currentFont = currentFont;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVersionStrategyAction.pending, (state) => {
      state.versionStrategy = { ...state.versionStrategy, loading: true };
      state.versionStrategy.versionStrategyData = null;
    });
    builder.addCase(loadVersionStrategyAction.fulfilled, (state, { payload }) => {
      state.versionStrategy = { ...state.versionStrategy, loading: false };
      state.versionStrategy.versionStrategyData = payload;
    });
    builder.addCase(loadVersionStrategyAction.rejected, (state) => {
      state.versionStrategy = { ...state.versionStrategy, loading: false };
      state.versionStrategy.versionStrategyData = null;
    });

    builder.addCase(loadAdminFontsProjectSettingsAction.pending, (state) => {
      state.fontsProjectSettings.fontsProjectSettingsList = [];
      state.fontsProjectSettings.currentFont = '';
      state.fontsProjectSettings.loading = true;
    });
    builder.addCase(loadAdminFontsProjectSettingsAction.fulfilled, (state, { payload: { currentFont, allFonts } }) => {
      state.fontsProjectSettings.fontsProjectSettingsList = allFonts;
      state.fontsProjectSettings.currentFont = currentFont;
      state.fontsProjectSettings.loading = false;
    });
    builder.addCase(loadAdminFontsProjectSettingsAction.rejected, (state) => {
      state.fontsProjectSettings.fontsProjectSettingsList = [];
      state.fontsProjectSettings.currentFont = '';
      state.fontsProjectSettings.loading = false;
    });

    builder.addCase(deleteFontsAction.pending, (state) => {
      state.fontsProjectSettings.fontsProjectSettingsList = [];
      state.fontsProjectSettings.currentFont = '';
      state.fontsProjectSettings.loading = true;
    });
    builder.addCase(deleteFontsAction.fulfilled, (state, { payload: { currentFont, allFonts } }) => {
      state.fontsProjectSettings.fontsProjectSettingsList = allFonts;
      state.fontsProjectSettings.currentFont = currentFont;
      state.fontsProjectSettings.loading = false;
    });
    builder.addCase(deleteFontsAction.rejected, (state) => {
      state.fontsProjectSettings.fontsProjectSettingsList = [];
      state.fontsProjectSettings.currentFont = '';
      state.fontsProjectSettings.loading = false;
    });
  },
});

export const { setSlice, setVersionStrategy, updateCurrentFontSettings, updateFontSettings } = adminSettingsSlice.actions;

export default adminSettingsSlice.reducer;
